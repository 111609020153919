

.form {
  margin-top: 10px;
  margin-left: 40px;
  margin-bottom: 10px;
  width: 60vw;
  padding: 15px;
  background-color: white;
}

.admin-dash .table{
  padding: 20px;
  margin-top: 40px;
  width: fit-content;
}

.admin-dash .table-header{
  display: table-cell;
  padding-right: 20px;
  color: var(--strong-content-color);
  border-bottom: 1px solid black;
}

.admin-dash .table-item{
  display: table-cell;
  padding-right: 20px;
  color: var(--primary-content-color);
  border-bottom: 1px solid grey;

}

.admin-dash .table-row{
  display: table-row;
  margin-bottom: 4px;
}


.admin-dash  .break{
  margin-bottom: 12px;
}

.admin-dash .btn{
  margin-right: 8px;
}


.admin-dash .tabs{
  margin-bottom: 20px;
}

.admin-dash .tab{
  display: inline-block;
  padding: 10px;
  font-size: 15px;
  cursor: pointer;
}


.admin-dash .tab.selected{
  font-weight: 600;
}


.rbc-calendar {
  flex: 1;
  min-height: 580px;
}
