
.footer{
  background: rgba(0,0,0,0.03);
  font-family: "Open Sans";
  font-size: 13px;
  color: var(--primary-content-color);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 108px;
  padding-top: 42px;
}

.new .footer {
  background: rgba(0,0,0,0.03);
  font-family: "Open Sans";
  font-size: 13px;
  color: var(--primary-content-color);
  position: relative;
  bottom: unset;
  width: 100%;
  height: 108px;
  margin-top: auto;
  padding-top: 0px;
}

.footer.simple{
  background: transparent;
}

.footer .copyright{
  /* Copyright © 2019 1up: */
  opacity: 0.6;
  font-family: "Open Sans";
  font-size: 13px;
  color: var(--primary-content-color);
  padding-top: 3px;
  margin-left: 17px;
}



.footer .divider{
  width: 1px;
  height: 26px;
  background: #D8D8D8;
  margin-left: 13px;
  margin-right: 13px;
  display: inline-block;
}

.footer .sitemap{
  border-bottom: 1px solid rgba(132, 132, 132, 0.08);
  width: 100%;
  padding-bottom: 39px;
  margin-bottom: 29px;

}

.footer .list{
  margin-right: 90px;
  display: inline-block;
  vertical-align: top;
}

.footer .list-header{
  font-weight: 600;
  color: var(--strong-content-color);
}

.footer ul {
  list-style: none;
}

.footer li {
  line-height: 26px;
}

.footer .content{
  width: 996px;
  margin-left:auto;
  margin-right: auto;
}

.footer.simple .content{
  width: 100%;
}

.footer.big {
  height: 450px;
  padding-top: 40px;
}

.footer .logo{
  height: 29px;
  margin-top: 0;
}

.footer .others{
  /* position: absolute;
  right: 0; */
  display: inline-block;
  float: right;
  vertical-align: top;
}


.footer .others a {
  line-height: 26px;
  height: 26px;
  display: inline-block;
  vertical-align: top;
}

.footer .simple{
  width: 100%;
  position: relative;
}
