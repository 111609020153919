
.login{
  margin-left: auto;
  margin-right: auto;
  margin-top: 133px;
  padding-bottom: 110px;
  width: 822px;
}

.signup-card{
  width: 371px;
  display: inline-block;
  background: #FFFFFF;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,0.07);
  border-radius: 5px;
  padding: 20px;
}

.signup-card .password .pass-label{
  display: inline-block;
}

.signup-card .password img{
  display: inline-block;
  height: 12px;
  margin-left: 6px;
  margin-bottom: 2px;
}

.login-form{
  width: 371px;
  padding: 20px;
  margin-left: 80px;
  display: inline-block;
  vertical-align:top;
  position: relative;
  padding-bottom: 52px;
}

.login .title{
  font-family: "Open Sans";
  font-size: 28px;
  color: var(--primary-content-color);
  margin-bottom: 8px;
}

.login .label{
  font-family: "Open Sans"; font-weight: 600;
  font-size: 15px;
  color: var(--primary-content-color);
  letter-spacing: -0.21px;
  padding: 0;
  margin: 0;
}

.signup-card .label{
  display: block;
  text-align: left;
}

.signup-card .password{
  display: inline-block;
  width: 160px;
}

.signup-card .re-password{
  display: inline-block;
  width: 160px;
  margin-left: 11px;
}

.signup-card .form-group{
  margin-bottom: 20px;
}

.signup-card .source-dropdown{
  width: 100% !important;
}

.signup-card .sort-box{
  margin-top: 0;
}

.signup-card .sort-box .dropdown-menu{
  top: 38px;
  width: 100%;
}

.login .text-field{
  margin-top: 8px;
  float: none;
  /* margin-bottom: 24px; */

}

.signup-card .custom-button{
  float: none;
}

.login-form .custom-button{
  position: absolute;
  right: 20px;
  bottom: 0;
}
.login-form .error-state{
  position: absolute;
  left: 20;
  bottom: 40px;
}
.login-form .forgot{
  position: absolute;
  left: 20px;
  bottom: 5px;
  font-family: "Open Sans";
  font-size: 13px;
  color: var(--primary-action-color);
}
.login-form .forgot:focus, .login-form .forgot:hover{
  color: #EAA600;
}


.signup-card .disclaimer{
  opacity: 0.8;
  font-family: "Open Sans";
  font-size: 12px;
  color: var(--primary-content-color);
  text-align: center;
  margin-top: 18px;
  padding-left: 20px;
  padding-right: 20px;
}

.check-email{
  height: 289px;
  width: 800px;
  margin-top: 200px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  padding-top: 27px;
  margin-bottom: 108px;
}

.check-email .title{
  font-size: 28px;
  color: var(--strong-content-color);
  margin-top:15px;
}

.check-email .text{
  font-size: 17px;
  color: var(--primary-content-color);
  text-align: center;
  opacity: 0.8;
  width: 422px;
  margin-top: 7px;
  margin-bottom: 23px;
  margin-left: auto;
  margin-right: auto;

}

.check-email .custom-button{
  float: none;
  width: 296px;
}

.check-email .heart-logo{

}

.verify .content p{
  /* We’ve sent an email: */
  font-family: "Open Sans";
  font-size: 15px;
  color: var(--primary-content-color);
  margin-bottom: 20px;
  opacity: 1;
}

.verify .content b{
  color: var(--strong-content-color);
  font-weight: 600;
}

.verify .cancel{
  color: var(--highlight-color);
  position: absolute;
  bottom: 0;
  font-size: 15px;
  line-height: 30px;
  cursor: pointer;
}

.customize-modal.verify .title {
  opacity: 0.5;
  font-family: "Open Sans";
  font-size: 28px;
  color: #000000;
  margin-bottom: 20px;
}
