.card.event{
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 14px;
  padding-bottom: 22px;
  width: 482px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-left: 30px;
  margin-bottom: 40px;
}

.event .image{
  position: absolute;
  top: 19px;
  right: 16px;
  height: 90px;
}

.event .location{
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
}

.event .date{
  font-size: 18px;
  color: var(--primary-content-color);
  letter-spacing: -0.26px;
  margin-bottom: 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space:nowrap;
}

.nav-links img{
  display: inline-flex;
}

.event .date b{
  font-weight: 600;
  color: var(--strong-content-color);
}

.event .details{
  /* Jungle Party Package: */
  font-size: 13px;
  color: var(--primary-content-color);
  letter-spacing: -0.19px;
  line-height: 22px;
  margin-bottom: 10px;
}

.event .details img{
  margin-right: 5px;
  margin-top: -2px;
  display: inline-flex;
  /* width: 16px;
  height: 16px; */
}

.event .shared img {
  display: inline-flex;
}


.event .details b{
  font-weight: 600;
  color: var(--strong-content-color);
}

.event .custom-button{
  float: none;
  width: 234px;
}

.event .secondary-button{
  float: none;
}

.event .canceled-event{
  position: absolute;
  left: 20px;
  transform: rotate(-30deg);
  font-size: 50px;
  color: var(--missing-color);
}
