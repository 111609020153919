.vendor-dash{

}

.vendor-dash .section-head{
  font-weight: 600;
  font-size: 18px;
  color: var(--strong-content-color);
  padding-bottom: 20px;
}

.vendor-dash .vendor-tabs{
  width: 996px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.vendor-dash .dash-tab{
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  color: var(--primary-content-color);
  padding-right: 32px;
}

/* .vendor-dash .dash-tab.first{
  padding-right: 32px;
} */

.vendor-dash .dash-tab.selected{
  font-weight: 700;
  color: var(--vendor-color);
}

.vendor-dash .header.logo{
  margin-top: 36px;
}

.notification-dot {
  height: 18px;
  width: 18px;
  background-color: var(--primary-content-color);
  border-radius: 50%;
  display: inline-block;
  color: white;
  position: relative;
  margin-bottom: 2px;
  vertical-align: middle;
}

.notification-dot.selected {
  background-color: var(--vendor-color);
}

.notification-count{
  font-size: 12px;
  width: 100%;
  left: 0;
  font-weight: bold;
  text-align: center;
  position: absolute;
}

.vendor-dash .divider{
  height: 1px;
  background: rgba(87, 87, 87, 0.1);
  width: 100%;
  margin-bottom: 12px;
  margin-top: 16px;
}

.vendor-dash .divider.shifted{
  width: 594px;
  margin-left: 16px;
  margin-top: 12px;

}

.vendor-dash .chevron{
  margin-left: 12px;
  margin-bottom: 2px;
  display: inline;
}

.vendor-dash .events-decription, .vendor-dash .servicing{
  display: inline-block;
  margin-left: 20px;
  color: var(--primary-content-color);
}

.vendor-dash .events-decription b, .vendor-dash .servicing b{
  color: var(--primary-content-color);
  font-weight: 600;
}

.vendor-dash .your-events .title{
  font-weight: 600;
  font-size: 15px;
  color: var(--strong-content-color);
}

.vendor-dash .your-events .bottom{
  padding-top: 12px;
}

.vendor-dash .account-page{
  margin-top: 20px;
}

.vendor-dash .adder-modal{
  border: none;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: none;
}

.vendor-dash .custom-button{
  background-color: var(--vendor-color);
  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
}

.vendor-dash .custom-button:hover{
  background-color: var(--vendor-color);
}

.vendor-dash .custom-button:hover, .vendor-dash  .custom-button:focus{
  background: #27578E;
  outline: none;
}

.vendor-dash .custom-button.disabled{
  background-color: rgba(63, 115, 174, .4);
}

.vendor-dash .rbc-calendar{
  height: 800px;
}

.vendor-dash .rbc-today {
  background-color: rgba(158, 221, 34, 0.2);
}

.vendor-dash  .instructions-header{
  font-size: 20px;
  font-weight: 600;
  color: var(--strong-content-color);
  letter-spacing: -0.21px;
  margin-bottom: 12px;
}

.vendor-dash .instructions-subtext{
  font-size: 15px;
  color: var(--primary-content-color);
  letter-spacing: -0.21px;
  margin-top: -12px;
  margin-bottom: 20px;
}
