

.testimonials{
  width: 100%
}

.static-page-header{
  opacity: 0.5;
  font-size: 44px;
  color: #000000;
  width: 996px;
  margin-left: auto;
  margin-right: auto;
}

.testimonials .section-wrapper{
  margin-bottom: 60px;
}

.page-subheader{
  opacity: 0.5;
  font-size: 15px;
  color: var(--primary-content-color);
  letter-spacing: -0.21px;
  width: 996px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 65px;
}

.testimonials .section{
  width: 996px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 40px;
  padding-bottom: 0px;
}

.testimonials .section .section-header{
  background: #9EDD22;
  box-shadow: 0 1px 10px 0 rgba(0,0,0,0.03);
  border-radius: 3px;
  font-weight: 700;
  font-size: 18px;
  color: #FFFFFF;
  padding-left: 12px;
  padding-right: 12px;
  width: fit-content;
}

.testimonials .section .content{
  width: 529px;
  padding-top: 47px;
  padding-right: 39px;
  display: inline-block;
}

.testimonials .section .special-image{
  margin-top: 18px;
  vertical-align: middle;

}
.testimonials .section .special-image.segment{
  margin-top: 30px;
}

.testimonials  .collage{
  width: 396px;
  display: inline-block;
  vertical-align: top;
}

.testimonials .section .card{
  display: inline-block;
}


.testimonials .section .summary{
  opacity: 0.5;
  font-size: 28px;
  color: #000000;
  margin-bottom: 20px;
}

.testimonials .section .text{
  font-size: 15px;
  color: var(--primary-content-color);
  letter-spacing: -0.46px;
}

.testimonials .section .text p{
  margin-bottom: 20px;
}

.testimonials .quote{
  width: 512px;
  display: inline-block;
  vertical-align: middle;
}

.testimonials .quote.left{
  margin-left: 55px;
}


.testimonials .card .card-contents{
  padding: 20px;
  font-size: 15px;
  color: var(--primary-content-color);
  letter-spacing: -0.46px;
}

.testimonials .card .card-contents p{
  margin-top: 12px;
}

.testimonials .left{
  padding-left: 55px;
}

.testimonials .picture{
  margin-top: 20px;
  margin-left: 54px;
}

.testimonials .picture img{
  width: 58px;
  height: 58px;
  border-radius: 50%;
  display: inline-block;
}

.testimonials .name-title{
  display: inline-block;
  vertical-align: middle;
  margin-left: 12px;
}

.testimonials .name-title .name{
  font-weight: 600;
  color: var(--strong-content-color);/* David McGavern: */
  font-size: 15px;
  letter-spacing: -0.27px;
}

.testimonials .name-title .title{
  color: var(--primary-content-color);
  font-size: 13px;
  letter-spacing: -0.24px;
}

.testimonials .quote .card{
  border-radius: 15px;
}
