.add-remove-range{
  height: 32px;
  line-height: 32px;
  display: inline-block;
  /* border: 1px solid rgba(132,132,132, 0.6); */
  width: 32px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  color: #C0CCDA;
  letter-spacing: 0;
  margin-right: 6px;
  border: 1px solid #EEF1F4;
  border-radius: 2px;
  transition: .2s ease-out;
  cursor: pointer;
}

.add-remove-range:hover{
  background: #F2F2F2;
  color: #484848;
  letter-spacing: 0;
}


.workhours .rc-time-picker-input:focus{
  border: 1px solid var(--input-highlight);
  outline: none;
  box-shadow: 0 0;
}

.workhours .custom-button{
  margin-top: 10px;
  margin-right: 39px;
  margin-left: 0;

  width: 132px;
  display: inline-block;
  float: none;
  font-size: 18px;
  line-height: 32px;
  font-weight: normal;
}
