.packages-page{
  /* margin-top: 50px; */
  position: relative;
  margin-bottom: 108px;
}

.package-regular {
  overflow: hidden;
  width: 335px;
  height: 290px;
  display: inline-block;
  margin: 0;
  position: relative;
  text-overflow: ellipsis;

}

/* .reg-package-container .heart{
  position: absolute;
  right: 52px;
  bottom: 14px;
  height: 24px;
  width: 24px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
} */


.reg-package-container .add{
  cursor: pointer;
  /* position: absolute;

  right: 16px;
  bottom: 8px; */
}

.reg-package-container .in-cart{
  position: absolute;
  right: 16px;
  bottom: 8px;
}



.reg-package-container .heart{
  /* position: absolute;
  right: 48px;
  bottom: 12px;
  height: 24px;
  width: 24px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px; */
  height: 32px;
  width: 32px;
}

.reg-package-container .availability-dropdown{
  /* position: absolute; */
  /* right: -1px;
  bottom: 1px; */
}


.package_list{
  min-height: 680px;
}

.package_list .box-wrapper{
  position: absolute;
  top: 35px;
  right:0;
}

.package_list .sublist li{
  margin-left: 50px;
  margin-bottom: 30px;
}

.package_list .title-wrapper{
  margin-left: 50px;
}

.sidebar{
  width: 198px;
  display: inline-block;
  position: fixed;
  width: 240px;
  height: 100%;
  padding-top: 101px;
  text-align: right;
  padding-right: 40px;
  background-color: var(--background-color);
  z-index: 5;
  text-transform: capitalize;
}

.package_list{
  width: auto;
  position: relative;
  padding-left: 195px;
  padding-top: 35px;
  z-index: 1;
  transition: .2s ease-out;
}

@media (max-width: 1350px) {

  .package_list .sublist li{
    margin-left: 28px;
  }

  .package_list ul{
    margin-left: 22px;
  }

  .packages-page .step{
    margin-left:90px;
  }


  .package_list {
    padding-left: 165px;
  }

  .packages-page .sidebar{
    width: 210px;
  }


}

@media (max-width: 1280px) {
  .sidebar {
    position: absolute;
    top: 0px;
    bottom: 0;
    height: calc(100vh - 50px);
    left: 0;
  }
}

@media (min-width: 768px) {


}

.package_list .sublist li{
  display:inline-block;
  padding:0;
  vertical-align: top;
}

.package_list ul{
  padding: 0;
}



.package-regular  .image {
  height: 167px;
  display: inline-block;
  margin-left: -45px;
  margin-top: 0;
}

/* .package-regular .add{
  cursor: pointer;
  position: absolute;
  right: 18px;
  bottom: 5px;
} */

.package-regular .edit{
  cursor: pointer;
  position: absolute;
  right: 76px;
  bottom: 4px;
  font-size: 20px;
}




.reg-package-container{
  position: relative;
  height: 290px;
}

.reg-package-container  li{
  margin: 0;
}

.reg-package-container .DayPicker{
}

.reg-package-container .calendar-wrapper{
  position: absolute;
  left: 295px;
  top: 275px;
  z-index: 500;
}

.reg-package-container .calendar-wrapper.right{
  left: auto;
  right: 0;

}


.package-regular .name {
  text-align: left;
  color: var(--strong-content-color);
  font-family: "Open Sans"; font-weight: 600;
  font-size: 17px;
  letter-spacing: -0.19px;
  margin-left: 11px;
  margin-top: 5px;
  display: block;
}

.package-regular .description {
  position: relative;
  opacity: 0.8;
  font-family: "Open Sans";
  text-align: left;
  font-size: 13px;
  color: var(--strong-content-color);
  letter-spacing: -0.19px;
  margin-left: 11px;
  margin-right: 10px;
  height: 40px;
  overflow: hidden;
}

.reg-package-container .price {
  /* position: absolute;
  bottom: 10px; */
  text-align: left;
  font-size: 16px;
  color: var(--strong-content-color);
  opacity: 0.8;
  font-family: "Open Sans"; font-weight: 600;
  /* margin-left: 11px; */
  letter-spacing: -0.19px;
}

.reg-package-container .price .faded {
  color: var(--primary-content-color);
  font-family: "Open Sans";
  letter-spacing: -0.19px;
}

.reg-package-container .price .num_hours{
  color: var(--primary-content-color);
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: normal;
  letter-spacing: -0.19px;
}

.package_list .sub{
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 24px;
  color: var(--primary-content-color);
  margin-bottom: 12px;
  letter-spacing: 0;
  margin-left: 50px;
  padding-top: 28px;
  padding-bottom: 5px;
}

.package-modal {
  box-shadow: 0 22px 49px 0 rgba(0,0,0,0.19);
  border-radius: 5px;
  margin-top: 70px;
  width: 996px;
  height: 90vh;
  position: relative;
}

.package-modal .bottom-bar{
  position: relative;
  height: 67px;
  z-index: 9;
  background-color: #FAFBFC;
  box-shadow: 0 -1px 2px 0 rgba(225,225,225,0.50);
  border-radius: 0 0 5px 5px;
}

.package-modal .content-wrapper{
  border-radius: 5px;
  overflow: hidden;
  width: 996px;
  min-height: 200px;
  height: 100%;
  position: relative;
  z-index: 2;

}

.package-modal .scroll-container{
  overflow-x: auto;
  overflow-x: hidden;

  height: 100%;
  position: relative
}


.close-wrapper{
  position: relative;
}

/* .modal-backdrop:after{
  content:"X";
  position: absolute;
  top: 70px;
  left: 10vw;
  background: black;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  z-index: 99999;
} */

.the-rest{
  display: flex;
  flex-direction: row;

}

.package-modal .name{
  /* Waffle Bar: */
  font-family: "Open Sans"; font-weight: 600;
  font-size: 28px;
  color: var(--strong-content-color);
  letter-spacing: -0.4px;
  display: inline-block;
  margin-left: 21px;

  margin-top: 18px;

}

.package-modal .image {
  height: 364px;
  display: inline-block;
  margin-left: -45px;
  margin-top: 0;
}

.package-modal .actual{
  background: rgba(0,0,0, 0.6);
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 196px;
  height: 27px;
  line-height: 27px;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 13px;
  color: #FFFFFF;
  letter-spacing: -0.13px;
}




.package-modal .details{
  width: 676px;
  display: inline-block;
  padding-bottom: 10px;
}

.package-modal .description{
  font-family: "Open Sans";
  font-size: 15px;
  color: var(--strong-content-color);
  letter-spacing: -0.46px;
  margin-left: 21px;
  margin-top: 19px;
  padding-right: 10px;
}

.package-modal .description p{
  margin-bottom: 15px;
}

.package-modal .description ul{
  margin-bottom: 15px;
}

.package-modal .components{
  display: inline-block;
  float: right;
  width: 310px;
  border-left: 1px solid rgba(0,0,0,0.17);
  padding: 19px;
}

.components .saved{
  font-size: 13px;
  color:  var(--primary-content-color);
  letter-spacing: -0.19px;
}

.package-modal .divider{
  margin-bottom: 0;
}

.package-modal .buttons{
  position: absolute;
  top: 382px;
  right: 20px;
}

.package-modal .buttons .available{
  height: 14px;
  display: inline-block;
}

.package-modal .buttons .text{
  font-size: 13px;
  color: var(--primary-content-color);
  letter-spacing: -0.19px;
  display: inline-block;
  margin-left: 4px;
  margin-right: 15px;
}


.package-modal .add{
  height: 34px;
  display: inline-block;
  background-color: var(--primary-action-color);
  color: white;
  border-radius: 5px;
  font-family: "Open Sans"; font-weight: 600;
  font-size: 15px;
  letter-spacing: -0.21px;
  cursor: pointer;
}

.package-modal .add:hover, .powerup-modal .add:focus{
  background: #EAA600;
  outline: none;
}

.package-modal .cart-icon{
  margin-left: 5px;
  height: 34px;
}


.package-modal .add:active{
  background: #AE7C01;
}

.package-modal .availability{
  height: 32px;
  line-height: 32px;
  /* position: absolute;
  top: 382px;
  right: 20px; */
  background-color: var(--primary-action-color);
  color: white;
  display: inline-block;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 5px;
}

.package-modal  .availability:hover, .package-modal .availability:focus{
  background: #EAA600;
  outline: none;
}

.package-modal .availability.calendar{
  background: #EAA600;
  outline: none;
  /* background: rgba(132,132,132,0.20); */
}
.package-modal .availability img{
  margin-bottom: 6px;
}

.package-modal .availability .text{
  margin-left: 10px;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 15px;
  color: white;
  letter-spacing: -0.21px;
}

.package-modal .modal-content .DayPicker{
  position: absolute;
  right: 0px;
  top: 420px;
  z-index: 500;
}

.package-modal .details .photos li{
  margin: 21px;
  margin-right: 0;
  margin-bottom: 0;
  display: inline-block;
}


.package-modal .details .photo-header{
    /* Photos From Past Jun: */
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 15px;
  color: var(--primary-content-color);
  letter-spacing: -0.21px;
  margin-top: 18px;
  margin-left: 21px;
}

.package-modal .details .photos li img{
  width: 190px;
}

.package-modal .add-cart{
  margin-left: 5px;
  height: 40px;
  margin-top: -2px;
}
.package-modal .add-text{
  margin-left: 6px;
  margin-right: 15px;
  line-height: 34px;
}

.package-modal .includes{
  font-family: "Open Sans"; font-weight: 600;
  font-size: 19px;
  color: var(--primary-content-color);
  letter-spacing: -0.27px;
}

.package-modal .params{
  margin-left: 21px;
  font-size: 19px;
  letter-spacing: -0.27px;
  color: var(--primary-content-color);
}



.params .price{
  font-family: "Open Sans"; font-weight: 600;
  color: var(--strong-content-color);
}

.params .emphasis{
  color: var(--strong-content-color);
}

.params .change{
  font-size: 13px;
  font-family: "Open Sans"; font-weight: 700;
  color: var(--primary-action-color);
  cursor: pointer;
}

.package-modal .short-includes{
  opacity: 0.8;
  font-family: "Open Sans";
  font-size: 13px;
  color: var(--primary-content-color);
  letter-spacing: -0.19px;
  margin-left: 21px;

}

.package-modal .added{
  background-color: rgba(255, 182, 0, 0.1);
  border-radius: 5px;
  height: 34px;
  line-height: 34px;
  position: absolute;
  top: 382px;
  right: 20px;
  width: 140px;
  text-align: center;
}

.package-modal .added .text{
  font-family: "Open Sans"; font-weight: 600;
  font-size: 15px;
  color: var(--primary-action-color);
  letter-spacing: -0.21px;
}

.package-modal .icon{
  margin-left: 5px;
  height: 32px;
}

.first-time{
  max-width: 1105px;
  min-width: 791px;
  height: 167px;
  text-align: center;
  position: relative;
  margin-bottom: 33px;
  padding-top: 33px;
  margin-left: 50px;
  margin-right: 10px;
}

.first-time .close-banner{
  color: var(--primary-content-color);
  position: absolute;
  left: 12px;
  top: 0px;
  font-size: 30px;
}
.first-time .close-banner:before{
  content: '\d7';
}


.first-time .head {
  font-family: "Open Sans";
  font-weight: normal;
  font-size: 20px;
  color: var(--strong-content-color);
  letter-spacing: -0.29px;
}

.first-time .head b{
  font-weight: 600;
}

.first-time .subhead{
  margin-top: 4px;
  margin-bottom: 16px;

  font-family: "Open Sans";
  font-size: 15px;
  color: rgba(132,132,132,0.60);
  letter-spacing: -0.21px;
}

.first-time .subhead b{
  color: var(--primary-content-color);
  font-weight: 600;
}

.first-time .custom-button{
  float: none;
  width: 249px;

}

.package-modal .close-button{
  position: absolute;
  top: 0;
  left: 21px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: fixed;
  background: white;
  box-shadow: 0 2px 7px 0 rgba(0,0,0,0.12);
  cursor: pointer;
  font-size: 36px;
}

.package-modal .close-button::after{
    content: '\d7';
    color: var(--primary-content-color);
    position: absolute;
    /* font-size: 40px; */
    text-align: center;
    line-height: 38px;
    width: 40px;
}

.package-modal .close-button:hover{
  font-size: 40px;
}


.carousel .packageListItem {
  /* background: #00558B;
  color: #fff;
  font-size: 36px;
  line-height: 100px;
  margin: 10px;
  padding: 2%; */
  position: relative;
  margin: 20px;

  /* text-align: center; */
}
.carousel .center .slick-center .packageListItem.unjaunty, .carousel .center .slick-center .packageListItem.jaunty {
  opacity: 1;
  transform: scale(1.10) rotate(0deg);
  z-index: 20;
}

/* .carousel .center .slick-center .packageListItem.unjaunty:hover, .carousel .center .slick-center .packageListItem.jaunty:hover {
  opacity: 1;
  transform: scale(1.10) rotate(0deg) translateY(-5px);
  z-index: 20;
} */

.carousel .slick-slider {
  height: 100%;
}

.carousel .center .packageListItem.unjaunty{
  opacity: 0.8;
  transition: all 1000ms ease;
  transform: scale(.95) rotate(-2deg) ;
}

.carousel .center .packageListItem.jaunty{
  opacity: 0.8;
  transition: all 1000ms ease;
  transform: scale(.95) rotate(2deg);
}

.carousel .smallcenter .slick-center .packageListItem.unjaunty, .carousel .smallcenter .slick-center .packageListItem.jaunty {
  opacity: 1;
  transform: scale(.95) rotate(0deg);
  z-index: 20;
}

.carousel .smallcenter .packageListItem.unjaunty{
  opacity: 0.8;
  transition: all 1000ms ease;
  transform: scale(.75) rotate(-2deg) ;
  margin-top: 0;
  margin-bottom:0;
}

.carousel .smallcenter .packageListItem.jaunty{
  opacity: 0.8;
  transition: all 1000ms ease;
  transform: scale(.75) rotate(2deg);
  margin-top: 0;
  margin-bottom:0;
}