

.newsletter .section{
  display: inline-block;
  vertical-align: top;
}

.newsletter .content{
  padding-left: 20px;
  padding-top: 30px;
  padding-bottom: 50px;
  padding-right: 20px;
  margin-top: 45px;
  margin-bottom: 100px;
}

.newsletter form{
  margin-right: 40px;
  width: 485px;
}

.newsletter label{
  font-weight: 600;
  color: var(--strong-content-color);
  font-size: 15px;
}

.newsletter .text{
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid rgba(132, 132, 132, 0.2);
  font-size: 15px;
  color: var(--primary-content-color);
  letter-spacing: -0.27px;
}

.newsletter .text-field{
  display: block;
  width: 298px;
  margin-bottom: 20px;
}

.newsletter .disclaimer{
  margin-left: 15px;
  width: 237px;
  opacity: 0.6;
  font-size: 13px;
  color: var(--primary-content-color);
  letter-spacing: -0.19px;
  display: inline-block;
  vertical-align: middle;
}
