
.application .question{
  font-size: 15px;
  font-weight: 600;
  color: var(--strong-content-color);
  letter-spacing: -0.21px;
  margin-bottom: 8px;
}

.application .subtext{
  font-size: 13px;
  color: var(--primary-content-color);
  letter-spacing: -0.21px;
  margin-top: -4px;
  margin-bottom: 8px;
}

.application .group{
  display: inline-block;
  margin-bottom: 38px;
  margin-top: 25px;
  vertical-align: top;
}

.application .group .text-field{
  width: 220px;
  margin-right: 12px;
}

.application textarea {
  width: 622px;
  margin-bottom: 38px;
  height: 90px;
  padding-top: 5px;
}

.application .sort-box{
  margin-bottom: 30px;
  width: fit-content;
}


.application .four-hour{
  margin-left: 20px;
  margin-bottom: 38px;
}

.application .four-hour label{
  font-weight: 400;
  color: var(--primary-content-color);
  line-height: 30px;
}

.application .text-field.long{
  width: 400px;
  margin-bottom: 38px;
}

.finished{
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  padding-top: 27px;
  padding-bottom: 10px;
}

.finished .title{
  font-size: 28px;
  color: var(--strong-content-color);
  margin-top:15px;
}

.finished .text{
  font-size: 17px;
  color: var(--primary-content-color);
  text-align: center;
  opacity: 0.8;
  width: 572px;
  margin-top: 7px;
  margin-bottom: 23px;
  margin-left: auto;
  margin-right: auto;
}

.application .my-autocomplete-container{
  margin-top: -38px;
}

.application .time {
  position: relative;
}

.application b {
  font-weight: 600;
  color: var(--strong-content-color)
}


.application .disclaimer{
  opacity: 0.8;
  font-family: "Open Sans";
  font-size: 12px;
  color: var(--primary-content-color);
  text-align: center;
  margin-bottom: 20px;
}

.application .rc-time-picker-input{
  height: 32px;
  width: 90px;
  line-height: 32px;
  font-size: 14px;
  color: #484848;
  letter-spacing: 0.3px;
  background: #F2F2F2;
}

.application .time{
  vertical-align: top;

}

.application .to{
  display: inline-block;
  vertical-align: top;
  padding-right: 12px;
  padding-left: 12px;
  opacity: 0.6;
  font-size: 14px;
  color: #484848;
  letter-spacing: 0.3px;
  padding-top: 6px;
}
