

.privacy{
  margin-top: 115px;
  margin-bottom: 450px;
  width: 996px;
  margin-left: auto;
  margin-right: auto;
}

.privacy .title{
  opacity: 0.5;
  font-family: "Open Sans";
  font-size: 44px;
  color: #000000;
  margin-bottom: 20px;

}

.privacy .subheader{
  opacity: 0.5;
  font-family: "Open Sans";
  font-size: 28px;
  color: #000000;
  margin-top: 51px;
  margin-bottom: 15px;
}

.privacy .content{
  font-family: "Open Sans";
  font-size: 15px;
  color: var(--primary-content-color);
  padding-bottom: 60px;
}

.privacy .content p{
  margin-bottom: 20px;
}


.privacy .content ul {
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  list-style-type: none;
}

.privacy .content ul > li {
  position: relative;
  padding-left: 10px;
}

.privacy .content ul > li:before {
  content: "•";
  position: absolute;
  left: 0;
}

.privacy .content ol{
  margin-left: 20px;
}
