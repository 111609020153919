.reschedule-modal{
  /* Rectangle 10: */
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 22px 49px 0 rgba(0,0,0,0.19);
  overflow: hidden;
  margin-top: 150px;
  padding: 20px;
  position: relative;
  width: 521px;
  z-index: 2041;
}


.reschedule-modal{
  /* Rectangle 10: */
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 22px 49px 0 rgba(0,0,0,0.19);
  overflow: hidden;
  margin-top: 150px;
  padding: 20px;
  position: relative;
  width: 521px;
  z-index: 2041;
}

.reschedule-modal .text-field.date{
  width: 168px;
  margin-bottom: 20px;
  display: inline-block;
}

.reschedule-modal .title{
  opacity: 0.5;
  font-family: "Open Sans";
  font-size: 28px;
  color: #000000;
  margin-bottom: 20px;
}

.reschedule-modal .subtitle{
  opacity: 0.5;
  font-family: "Open Sans";
  font-size: 17px;
  color: #000000;
}

.reschedule-modal .text{
  font-size: 17px;
  color: var(--primary-content-color);
  margin-top: 20px;
  margin-bottom: 10px;
}

.reschedule-modal .text b{
  font-weight: 600;
}

.reschedule-modal .text ol{
  padding-left: 40px;
}

.reschedule-modal.terms .text li{
  padding-bottom: 10px;
}

.reschedule-modal label{
  font-weight: normal;
  color: var(--primary-content-color);
}

.reschedule-modal .duration {
    display: inline-block;
    vertical-align: middle;
    width: 135px;
    height: 30px;
}

.reschedule-modal .duration{
    border: 0px solid;
    height: 42px;
    margin-top: -1px;
    margin-left: -1px;
    z-index: 9001;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.reschedule-modal .duration .duration-options.dropdown-menu{
  overflow: auto;
  max-height: 265px;
  top: 30px;
}

.reschedule-modal  .list-member {
    position: relative;
}

.reschedule-modal .list-member .number{
  position: absolute;
  right: 13px;
  top: 3px;
}

.reschedule-modal .list-member a {
    padding-left: 14px;
}


.reschedule-modal .time {
    position: relative;
    width: 104px;
    height: 30px;
    vertical-align: middle;
}

.text-field.person{
  padding-left: 25px;
}

.text-field.hour{
  padding-left: 25px;
}

.reschedule-modal.booking-modal{
  overflow: visible;
  width: 740px;
}

.reschedule-modal .duration-text{
  position: absolute;
  right: 20px;
  top: 0;
}

.reschedule-modal .to{
  padding-left: 4px;
  padding-right: 4px;
}

.reschedule-modal .duration{
  display: inline-block;
  vertical-align: middle;
  width: 135px;
  height: 30px;
}

.reschedule-modal .rc-time-picker-input{
  font-size: 14px;
  height: 30px;
  vertical-align: top;
  color: var(--primary-content-color);
}

.reschedule-modal .SingleDatePickerInput{
  height: 28px;
  margin-top: 0px;
}

.reschedule-modal .DateInput{
  height: 28px;
}

.reschedule-modal .people{
  position: relative;
  height: 30px;
  width: 300px;
}

.reschedule-modal .people-text{
  padding-left: 12px;
  bottom: 5px;
  position: absolute;
  left: 0;
  color: var(--primary-content-color);
}


.reschedule-modal .people .text-field{
  position: absolute;
}

.reschedule-modal .questions{
  font-weight: 600;
  font-size: 15px;
  color: var(--strong-content-color);
  letter-spacing: -0.21px;
  margin-bottom: 8px;
}


.reschedule-modal .disclaimer{
  color: var(--primary-content-color);
  font-size: 13px;
  margin-top: 2px;
}
