.custom-checkbox{
  height: 30px;
  width: 30px;
  background: transparent;
  border-radius: 3px;
  display: inline-flex;
  justify-content: center;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 8px;
}

.custom-checkbox.checked{
  background: var(--primary-action-color);
  border: 1px solid transparent;

}
.custom-checkbox .checkmark{
  /* margin-top: 5px; */
  width: 70%;
}



.custom-checkbox.unchecked{
  border: 1px solid rgba(132,132,132, 0.4)
}

.custom-checkbox .label{
  font-family: "Open Sans";
  font-size: 15px;
  color: var(--primary-content-color);
}

.custom-checkbox.checked .label{

  color: var(--strong-content-color);
}
