

.event-summary .view-details{
  font-size: 15px;
  color: var(--vendor-color);
  float: right;
  cursor: pointer;
}

.event-summary .overview-container{
  width: 100%;
}
.event-summary .details{
  color: var(--strong-content-color);
  margin-left: 50px;
}

.event-summary .details b{
  font-weight: 600;
}

.event-summary .details .sec{
  margin-right: 30px;
  display: inline-block;
}
