

.how-to-page{
  width: 100%
}

.static-page-header{
  opacity: 0.5;
  font-size: 44px;
  color: #000000;
  width: 996px;
  margin-left: auto;
  margin-right: auto;
}

.page-subheader{
  opacity: 0.5;
  font-size: 15px;
  color: var(--primary-content-color);
  letter-spacing: -0.21px;
  width: 996px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 65px;
}

.how-to-page .section{
  width: 996px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 50px;
}

.how-to-page .section .section-header{
  background: #9EDD22;
  box-shadow: 0 1px 10px 0 rgba(0,0,0,0.03);
  border-radius: 3px;
  font-weight: 700;
  font-size: 18px;
  color: #FFFFFF;
  padding-left: 12px;
  padding-right: 12px;
  width: fit-content;
}

.how-to-page .section .content{
  width: 529px;
  padding-top: 47px;
  /* margin-right: 39px; */
  display: inline-block;
}

.how-to-page .section .special-image{
  margin-top: 18px;
}
.how-to-page .section .special-image.segment{
  margin-top: 30px;
}

.how-to-page  .collage{
  width: 396px;
  display: inline-block;
  vertical-align: top;
}

.how-to-page .section .card{
  display: inline-block;
}


.how-to-page .section .summary{
  opacity: 0.5;
  font-size: 28px;
  color: #000000;
  margin-bottom: 20px;
}

.how-to-page .section .text{
  font-size: 15px;
  color: var(--primary-content-color);
  letter-spacing: -0.46px;
}

.how-to-page .section .text p{
  margin-bottom: 20px;
}
