
.request{
  display: flex;
}
.purple-bar{
  background-color: var(--vendor-color);
  /* height: 100%; */
  height: 100px;
  width: 4px;
  display: inline-block;
}

.vendor-dash .powerup-image-container{
  height: 40px;
  width: 40px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, .03);
  border-radius: 4px;
  display: inline-block;
  cursor: default;
  margin-top: 4px;
}

.vendor-dash .powerup-image-container .powerup-image{
  display: inline-block;
  height: 40px;
}


.vendor-dash .request .overview-container{
  margin-left: 12px;
  display: inline-block;
  vertical-align: top;
  flex-grow: 1;
}

.vendor-dash .name-date{
  display: inline-block;
  margin-left: 10px;
  vertical-align: top;
  margin-top: 2px;
}

.vendor-dash .name{
  font-weight: 600;
  font-size: 15px;
  color: var(--strong-content-color);
}

.vendor-dash .date{
  font-size: 15px;
  color: var(--primary-content-color);
}

.vendor-dash .date b{
  font-weight: 400;
  color: var(--strong-content-color);

}

.request .details{
  color: var(--strong-content-color);
  margin-left: 50px;
  margin-bottom: 4px;
}

.request .details b{
  font-weight: 600;
}

.account-page .request .custom-button{
  float: right;
  margin-top: 4px;
  margin-left: 12px;
  width: 94px;
}

.account-page .request .secondary-button{
  float: right;
  margin-top: 4px;
}

.request .details .sec{
  width: 175px;
  display: inline-block;
  vertical-align: top;
}
