.section-header{
  display: flex;
  margin-bottom: 3px;
  margin-top: 17px;
}

.section-header .header-name{
  font-size: 12px;
  color: var(--primary-content-color);
  float:left;
  margin-right: 6px;
}

.section-header .line{
  height: 1px;
  width:auto;
  overflow: hidden;
  margin-top: 8.5px;
  opacity: 0.04;
  background: #000000;
  flex-grow: 100;
}
