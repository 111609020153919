
.checkout-container.past-event{
  margin-top: 0px;
  padding-top: 40px;
}

.checkout-container.upcoming-event{
  margin-top: 0px;
  padding-top: 40px;
}


@media (max-width: 976px) {
  .checkout-container.upcoming-event{
    width: 976px;
    margin-left: 142px;
  }
}


.checkout-container.past-event .summary {
  border: none;
}

.past-event .survey-thanks{
  text-align: center;
}
.past-event .photos .secondary-button {
  float: none;
  margin: 0;
}

.past-event .survey-thanks .title{
  margin-top: 13px;
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 20px;
  color: var(--primary-content-color);
  text-align: center;
}

.past-event .survey-thanks .text{
  width: 433px;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.8;
  font-size: 15px;
  color: var(--primary-content-color);
  text-align: center;
}

.upcoming-event .items .custom-button{
  float: none;
  margin-top: 20px;
}

.card.warning{
  overflow: hidden;
  margin-bottom: 30px;
}

.events .warning .icon {
  height: 65px;
  width: 63px;
  text-align: center;
  background-color: #FF0036;
  display: inline-block;
  padding-top: 18px
}

.events .warning .text-container{
  display: inline-block;
  vertical-align: top;
  margin-left: 15px;
  margin-top: 13px;
}

.events .warning .text-container .text{
  font-weight: 600;
  font-size: 15px;
  color: var(--strong-content-color);
}
.events .warning .text-container .subtext{
  font-size: 13px;
  color: var(--primary-content-color);
}

.items .to-add{

}

.items .to-remove .powerup-name, .items  .quantity.to-remove{
  text-decoration: line-through;
}

.items .to-remove .powerup-price, .items .to-remove .powerup-price-per{
  /* text-decoration: none;
  color: red; */
  text-decoration: line-through;
}

/* .items  .to-remove .powerup-price::before, .items .to-remove .powerup-price-per::before{
  content: "-";
  color: red;
} */

.order .wrap.no-button{
  padding: 0
}

.events .cancel-event{
  font-weight: 600;
  font-size: 13px;
  color: #FF5200;
  cursor: pointer;
  width: 140px;

}

.events .cancellation-charge{
  font-size: 13px;
  color: var(--primary-content-color);
}

.custom-button.with-icon{
  display: block;
  float: none;
  padding-left: 65px;
  width: fit-content;
  padding-right: 35px;
  margin-top: 20px;
}

.items .rebook{
  position: relative;
}

.items .rebook-icon{
  position: absolute;
  bottom: 6px;
  left: 35px;
}


.upcoming-event .SingleDatePicker{
  height: 28px;
}

.upcoming-event .SingleDatePickerInput{
  height: 28px;
  margin-top: 0;
}
.upcoming-event .DateInput{
  height: 28px;
}

.upcoming-event .SingleDatePicker_picker{
  left: -13px !important;
  top: 31px;
}

.upcoming-event .order .summary{
  border-top-color: transparent;
}

.wishlist-view .delete-text{
  font-size: 13px;
  color: #FF5200;
  cursor: pointer;
  margin-left: 32px;
  display: inline-block;
}

.wishlist-view .checkout-details .booking{
  padding-right: 17px;
  margin-right: 28px;
}

.wishlist-view .booking.borderless{
  border-right: none;
}

.wishlist-view .wishlist .booking .disclaimer-text{
  margin-left: 0;
  font-size: 13px;
  line-height: normal;
  margin-top: 4px;
}

.wishlist-view .sharing{
  display: inline-block;
}

.wishlist-view .sharing .description {
  opacity: 0.6;
  font-size: 13px;
  color: var(--primary-content-color);
  letter-spacing: -0.19px;
  width: 345px;
}

.wishlist-view .sharing .share-link{
  width: 345px;
  overflow: scroll;
  text-overflow: clip;
  white-space: nowrap;
  cursor: pointer;
}

.wishlist-owner{
  opacity: 0.6;
  font-size: 15px;
  color: var(--primary-content-color);
  letter-spacing: -0.21px;
  margin-left: 18px;
}


.upcoming-event .vendor{
  margin-bottom: 12px;
  color: var(--primary-content-color);
  position: relative;
}


.upcoming-event .vendors {
  margin-top: 20px;
}

.upcoming-event .vendor .service-name {
  margin-bottom: 4px;
  font-size: 15px;
  font-weight: 600;
  color: var(--strong-content-color);
}

.upcoming-event .vendor .canceled-service {
  font-size: 20px;
  position: absolute;
  transform: rotate(-30deg);
  color: var(--missing-color);
  font-weight: 600;
}

.upcoming-event .checkout-details .other {
  width: 100%;
}

.upcoming-event .other .address.text-field {
  width: 357px;
}
