.account-page .contact{
    width: 100%
}

.account-page .custom-button{
  float: none;
  margin-top: 20px;
  margin-left: 20px;
}

.account-page .secondary-button{
  float: none;
  margin-top: 20px;

}

.account-page  .your-events .secondary-button{
  margin-top: 0;

}

.account-page{
  margin-top: 40px;
  padding-top: 30px;
}

.account-page .title-wrapper{
  margin-bottom: 0;
}

.account-page .card.section{
  margin-top: 0;
}

.account-page .referral{
  display: inline-block;
  width: 315px;
  vertical-align: top;
}

.account-page .referral .divider{
  margin-top: 12px;
  margin-bottom: 12px;
  opacity: 0.1;
  background: var(--strong-content-color);
  width: 100%;
  height: 1px;
}

.account-page .referral .card{
  padding: 20px;
}

.account-page .referral .subheader{
  font-weight: 600;
  font-size: 15px;
  color: var(--strong-content-color);
}

.account-page .referral .credit{
  /* $20.00: */
  font-weight: 200;
  font-size: 28px;
  color: var(--primary-content-color);
}
.account-page .referral .count{
  font-size: 13px;
  color: var(--primary-content-color);
  letter-spacing: -0.19px;
}

.account-page .referral .text{
  margin-top: 4px;
  font-size: 15px;
  color: var(--primary-content-color);
}

.account-page .referral .ref-wrapper, .wishlist-view .ref-wrapper {
  position: relative;
}

.account-page .referral .copy, .wishlist-view .copy{
  display: none;
  position: absolute;
  right: 1px;
  top:50%;
  color: var(--primary-action-color);
  font-size: 15px;
  font-weight: 600;
  margin-left: 8px;
  background: white;
  padding-left: 4px;
  padding-right: 3px;
  cursor: pointer;
  transform: translateY(-50%);

}

.account-page .referral .ref-wrapper:hover .copy, .wishlist-view .ref-wrapper:hover .copy{
  display: block;
}

.account-page .referral .text-field{
  display: inline-block;
  width: 100%;
  overflow: auto;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
}

.account-page .referral .subheader.ref{
  display: inline-block;
}
