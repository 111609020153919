.travel-fees{
  margin-bottom: 20px;
}

.travel-fees{
  margin-left: 16px;
}

.travel-fees .region{
  position: relative;
}

.travel-fees .fee{
  position: relative;
  display: inline-block;
  margin-left: 8px;
}

.travel-fees .sub-region{
  margin-left: 30px;
  position: relative;

}

.travel-fees .location{
  margin-left: 60px;
  position: relative;

}

.travel-fees .text-field{
  margin-bottom: 8px;
  width: 62px;
}

.travel-fees .location-label{

  font-weight: 600;
  color: var(--strong-content-color);
  font-size: 15px;
}

.travel-fees .sub-region .location-label{
  font-weight: 600;
  color: var(--strong-content-color);
  font-size: 15px;
}

.travel-fees .location .location-label{
  font-weight: normal;
  color: var(--strong-content-color);
  font-size: 15px;
}

.travel-fees .desc-text{
  color: var(--primary-content-color);
  font-size: 15px;
}

.travel-fees .money{
  position: absolute;
  left: 10px;
  top: 4px;
}
