.modal {
  box-shadow: 0 22px 49px 0 rgba(0,0,0,0.19);
  /* border-radius: 5px; */
}

.modal-content{
  border: 0;
  box-shadow: 0 0;
}

.modal-content{
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 0px;
}

.eventDetailsModal .modal-content{
  background-color: #FCFCFC;
}

.modal-content .divider{
  width: 100%;
  opacity: 0.2;
  border-top: 1px solid var(--primary-content-color);
  margin-top: 20px;
  margin-bottom: 15px
}

.modal-backdrop {
  background-color: white;
  opacity: 0.7 !important;
}

.modal-content .description ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.modal-content .description ul > li {
  position: relative;
  padding-left: 10px;
}

.modal-content .description ul > li:before {
  content: "•";
  position: absolute;
  left: 0;
}
