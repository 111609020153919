
.event-host{
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  margin-bottom: 77px
}

.event-host .main-section{
  margin-right: auto;
  margin-left: auto;
  width: 996px;
  padding-bottom: 26px;

}

.event-host .sect{
  margin-top: 58px;
  width: 286px;
  display: inline-block;
  margin-right: 60px;
  text-align: center;
  font-size: 15px;
  vertical-align: top;
  max-width: 100%;

}

.event-host .sect img{
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
}

.event-host .sect.last{
  margin-right: 0;
}
.event-host .sect .title{
  color: var(--strong-content-color);
  font-weight: 600;
  line-height: 28px;
}

.event-host .sect .text{
  color: var(--primary-content-color);
}

.event-host .party {
  padding-top: 61px;
  margin-right: auto;
  margin-left: auto;
  width: 996px;

}
.event-host .party .long-text {
  border-bottom: 1px solid rgba(0,0,0, 0.08);
  margin-bottom: 37px;
}

.event-host .party .text{
  font-size: 13px;
  color: var(--primary-content-color);
  letter-spacing: -0.24px;
  width: 522px;
  margin-right: 42px;
  margin-bottom: 36px;
  margin-top: 15px;
  display: inline-block;
}

.event-host .title-wrapper, .card.my-form .title-wrapper{
  display: block;
}


.event-host .party .text p{
  margin-bottom: 15px;
}

.event-host .my-form{
  margin-top:  56px;
}

.event-host .requirements .sect{
  width: 310px;
  margin-right: 30px;
  display: inline-block;
  vertical-align: top;
  margin-top: 0;
}

.event-host .requirements .sect.last{
  margin-right: 0;
}


.requirements .section-header{
  font-size: 15px;
  font-weight: 600;
  color: var(--stong-content-color);
}

.requirements ul.description{
  font-size: 13px;
  letter-spacing: -0.27px;
  color: var(--primary-content-color);
  text-align: left;
}

.card.my-form .question{
  font-size: 15px;
  font-weight: 600;
  color: var(--strong-content-color);
  letter-spacing: -0.21px;
  margin-bottom: 8px;
}

.card.my-form .group{
  display: inline-block;
  margin-bottom: 38px;
  margin-top: 25px;
  vertical-align: top;
}

.card.my-form .group .text-field{
  width: 220px;
  margin-right: 12px;
}

.card.my-form textarea {
  width: 622px;
  margin-bottom: 38px;
  height: 90px;
  padding-top: 5px;
}

.card.my-form .custom-button{
  width: 196px;;
  height: 44px;
  line-height: 44px;
  display: block;
  float: none;
}
