.powerup-page .sort-box{
  display: inline-block;
  position: absolute;
  right: 48px;
}

.sort-box .sort-text{
  border: 1px solid rgba(132,132,132,0.60);
  font-size: 13px;
  line-height: 28px;
  border-radius: 3px;
  padding-left: 8px;
  padding-right: 20px;
  min-width: 130px;
  width: fit-content;
}

.sort-box .sort-text .lab{
  color: rgba(132, 132, 132, 0.6);
}

.sort-box .dropdown-menu{
  top: 100%;
  min-width: 130px;
  max-height: 240px;
  overflow: scroll;
  scrollbar-width: none;
  left: unset;
  right: 0;
}

.sort-box.left-drop .dropdown-menu {
  left: 0;
  right: unset;
}


.dropdown.open .sort-text{
  background: rgba(132,132,132,0.20);
  border: 1px solid var(--primary-content-color);
}

.sharing .sort-box{
  margin-top: 12px;
}
