.locations-section ol{
  list-style-type: none;
}

.locations-section ol  ol {
  margin-left: 20px;
}

.locations-section .rct-icon-expand-open, .locations-section .rct-icon-expand-close{
  position: relative;
}

.locations-section .rct-icon-expand-open::before{
  position: absolute;
  right: 0px;
  top: -4px;
  width: 0;
  content: "";
  margin-top: 48%;
  vertical-align: middle;
  border-top: 6px dashed var(--primary-content-color);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}

.locations-section .rct-icon-expand-close::before{
  position: absolute;
  /* right: 0px; */
  right: 50%;
  top: 50%;
  width: 0;
  content: "";
  /* margin-top: 48%; */
  vertical-align: middle;
  border-top: 6px dashed var(--primary-content-color);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  transform: rotate(-90deg) translate(100%, 100%);
}

.locations-section .rct-node-icon{
  margin-left: -42px;
  margin-right: 5px;
}


.locations-section .react-checkbox-tree label:hover {
    background: none;
}

.locations-section .react-checkbox-tree label {
    margin-bottom: 6px;
}

.locations-section .rct-node-icon *{
  width: inherit;
}


.locations-section .rct-icon{
  height: 30px;
  width: 30px;
  background: transparent;
  border-radius: 3px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 8px;
  color: transparent;
}

.locations-section .rct-icon.rct-icon-check::before{
  content: "";
}

.rct-icon.rct-icon-check{
  background: var(--primary-action-color);
  border: 1px solid transparent;

}

.rct-icon.rct-icon-half-check{
  background: rgba(87, 87, 87, .3);
  border: 1px solid transparent;
  position: relative;
}

.locations-section .rct-icon.rct-icon-uncheck::before{
  content: "";
}

.locations-section .rct-icon.rct-icon-half-check::before{
  position: absolute;
  content: "";
  color: white;
  font-size: 20px;
  background: var(--primary-action-color);
  opacity: 1 ;
  /* rgba(87, 87, 87, .3); */
  /* border: 1px solid transparent; */
  height: 30px;
  width: 30px;
  border-radius: 3px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  top: -1px;;
  left: -1px;
  z-index: 1000;
}

.locations-section .rct-icon.rct-icon-half-check::after{
  position: absolute;
  content: "";
  background-color: white;
  height: 3px;
  width: 18px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  top: 49%;
  left: 5px;
  z-index: 1000;
}

.locations-section .rct-node-parent ol{
  position: relative;
  margin-bottom: 4px;
}

.locations-section .rct-node-parent ol ol:before{
  height: 100%;
  width: 1px;
  background-color: #D8D8D8;
  position: absolute;
  left: 47px;
  top: 0px;
  content: "";
}

.application.add-service .desc-text{
  color: var(--primary-content-color);
  font-size: 15px;
}

.application.add-service .money{
  position: absolute;
  left: 10px;
  top: 4px;
}

.application.add-service .percent{
  position: absolute;
  right: 10px;
  top: 4px;
}


.rct-icon.rct-icon-uncheck{
  border: 1px solid rgba(132,132,132, 0.4)
}

.application.add-service .text-field{
  margin-bottom: 30px;
}

.selection-modal .service-selected {
  background: var(--vendor-color);
}

.selection-modal .powerup-name.service-selected {
  color: white;

}

.selection-modal .powerup{
  vertical-align: middle;
  margin-top: 4px;
  margin-bottom: 4px;
}

.selection-modal .powerup .powerup-name{
  max-width: 300px;
}

.selection-modal .powerup:hover .powerup-details {
  line-height: 40px;
  height: 40px;
}

.selection-modal .view-button{
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.selection-modal .view-button.service-selected{
  color: white
}

.selection-modal .item-adder.cart-contents{
  padding-left: 0;
  padding-top: 4px;
}

.formatted-description{
  margin-left: 21.5px;
  margin-right: 21.5px;
  flex: 1;
  /* Whether for actual b: */
  font-family: "Open Sans"; font-weight: 600;
  font-size: 13px;
  color: var(--primary-content-color);
  letter-spacing: -0.4px;
}

.formatted-description p{
  margin-bottom: 13px;
}

.formatted-description ul{
  margin-bottom: 13px;
  margin-left: 15px;
}

.formatted-description ul ul{
  margin-bottom: 0;
}

.service-selection-section .formatted-description {
  width: 600px;
}

.service-selection-section .instructions{
  width: 600px;
}

.service-selection-section .service-name{
  margin-top: 20px;
  border-top: 1px solid rgba(132,132,132, .2);
  padding-top: 20px;
}

.application .requirements{
  margin-bottom: 30px;
}

.application .requirements .opt{
  margin-bottom: 12px;
}

.application .requirements .opt .text-field{
  margin-bottom: 0px;
  margin-left: 12px;
  width: 90px;
  margin-right: 8px;
}

.application .requirements .opt label{
  font-weight: 600;
  color: var(--strong-content-color);
  text-transform: capitalize;
}

.application .sort-box:hover{
  background: #F2F2F2;
}
