.services-view .services-table{
  width: 100%;
}


.services-view .powerup{
  margin-bottom: 12px;
  max-width: 400px;
}


.services-view .powerup .powerup-name{
  max-width: 300px;
  font-size: 15px;
  cursor: default;
}

.services-view .powerup .powerup-wrapper{
  max-width: 300px;
}



.services-view .services-table th{
  font-weight: 600;
  font-size: 13px;
  color: var(--primary-content-color);
  padding-left: 12px;

}

.services-view .services-table td{
  padding-left: 12px;
}

.services-view table {
  border-collapse: collapse;
}

.services-view .services-table th{
  height: 27px;
  position: relative;
  border-bottom: 1px solid rgba(87, 87, 87, .2) ;
}

.services-view .services-table td{
  border-top: 1px solid rgba(132, 132, 132, .2) ;
  border-right: 1px solid rgba(132, 132, 132, .2) ;
  max-width: 150px;

}

.services-view .services-table td.stl{
  border-right: 1px solid rgba(132, 132, 132, .1) ;
}

.services-view .services-table td.last{
  border-right: none ;
  width: 118px;
  padding-left: 15px;
}

.services-table .pricing-row{
  padding-top: 12px;
  padding-bottom: 12px;
}



.small-divider{
  width: 1px;
  background-color: rgba(132, 132, 132, .1);
  height: 20px;
  /* margin-top: 3px; */
  margin-right: 12px;
  position: absolute;
  top: 3px;
  left: 0;
}

.status{
  display: inline-block;
  height: 7px;
  width: 7px;
  margin-right: 6px;
  background-color: var(--highlight-color);
  border-radius: 50%;
  vertical-align: middle;
  margin-bottom: 2px;
}

.status.in-review{
  background-color: yellow;
}

.status.paused{
  background-color: var(--missing-color);
}
