.list-member-pricing{
    display: inline-block;
    text-align: right;
    width: 156px;
    vertical-align: middle;
}

.list-member-details{
  width: 437px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.list-member-pricing .total{
  font-size: 17px;
  color: var(--primary-content-color);
}

.list-member-pricing .per{
  opacity: 0.6;
  font-size: 13px;
  color: var(--primary-content-color);
}

.list-member-pricing.canceled .total{
  text-decoration: line-through;
}

.list-member-pricing.canceled .per{
  text-decoration: line-through;
}

.cart-contents .list-member-details .notes{
  width: 389px;
  margin-left: 48px;
  overflow: scroll;
  margin-top: 6px;
  padding-top: 6px;
  height: 32px;
  line-height: normal;
}


.cart-contents .list-member-details .notes.disabled{
  height: auto;
  padding-bottom: 5px;
}

.list-member{
  margin-bottom: 6px;
}

.list-member .cancellation-fee{
  line-height: 30px;
  margin-top: 6px;
  margin-left: 48px;
}

.list-member .start-time-selector{
  position: absolute;
  top: 8px;
  right: 0;
}

.list-member .waive-fee{
  display: inline-block;
  margin-left: 30px;
}
