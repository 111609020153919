.survey .bottom{
  position: relative;
  height: 80px;
}

.survey .bottom .custom-button{
  margin-bottom: 13px;
  display: inline-block;
  float: none;
}

.survey .bottom .secondary-button{
  margin-bottom: 13px;
  display: inline-block;
  float: none;
  margin-right: 12px;
}


.survey .card{
  padding: 20px;
}

.survey .dots{
  margin-bottom: 20px;
  margin-top: 20px;
  position: relative;
}

.survey .dot.empty{
  height: 20px;
  width: 20px;
  border: 1px solid var(--primary-content-color);
  border-radius: 50%;
  display: inline-block;
  color: white;
  position: relative;
  vertical-align: middle;
}

/* .survey .dot.full{
  height: 20px;
  width: 20px;
  background-color: var(--highlight-color);
  border-radius: 50%;
  display: inline-block;
  color: white;
  position: relative;
  vertical-align: middle;
  animation-name: move-right;
  animation-duration: 1s;
} */

.survey .dot.full{
  height: 20px;
  width: 20px;
  background-color: var(--highlight-color);
  border-radius: 50%;
  color: white;
  position: absolute;
  left: 0;
  top: 2px;
  vertical-align: middle;
  transition: left;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}


.survey .dot.check{
  height: 20px;
  display: inline-block;
  cursor: pointer;
}

.survey .line{
  background-color: var(--primary-content-color);
  height: 1px;
  width: 64px;
  margin: 2px;
  display: inline-block;
  vertical-align: middle;
  opacity: 0.2
}
