@tailwind base;
@tailwind components;
@tailwind utilities;

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

html, body {
 margin: 0px;
 padding: 0px;
 border: 0px;
 font-size: 16px;
 }
 body {
 /* font: 1em/1.25 Arial, Helvetica, sans-serif; */
 }

 /* Headlines */
 h1, h2, h3, h4, h5, h6 {
 margin: 0;
 padding: 0;
 /* font-weight: normal; */
 /* font-family: Arial, Helvetica, sans-serif; */
 }

 /* Text Styles */
 p, th, td, li, dd, dt, ul, ol, blockquote, q, acronym, abbr, a, input, select, textarea {
 margin: 0;
 padding: 0;
 /* font: normal normal normal 1em/1.25 Arial, Helvetica, sans-serif; */
 }
 blockquote {
 margin: 1.25em;
 padding: 1.25em
 }
 q {
 font-style: italic;
 }
 acronym, abbr {
 cursor: help;
 border-bottom: 1px dashed;
 }
 small {
 font-size:.85em;
 }
 big {
 font-size:1.2em;
 }

 /* Links and Images */
 a, a:link, a:visited, a:active, a:hover {
 text-decoration: none;
 }
 img {
 border: none;
 }

 /* Tables */
 table {
 margin: 0;
 padding: 0;
 border: none;
 }

 /* Forms */
 form {
 margin: 0;
 padding: 0;
 display: inline;
 }
 label {
 cursor: pointer;
 }

 /* Common Classes */
 .clear { clear: both; }
 .floatLeft { float: left; }
 .floatRight { float: right; }
 .textLeft { text-align: left; }
 .textRight { text-align: right; }
 .textCenter { text-align: center; }
 .textJustify { text-align: justify; }
 .blockCenter { display: block; margin-left: auto; margin-right: auto; } /* remember to set width */
 .bold { font-weight: bold; }
 .italic { font-style: italic; }
 .underline { text-decoration: underline; }
 .noindent { margin-left: 0; padding-left: 0; }
 .nomargin { margin: 0; }
 .nopadding { padding: 0; }
 .nobullet { list-style: none; list-style-image: none; }


html {
  position: relative;
  width: 100%;
  height: 100%;
}
:root  {
  --highlight-color: #9EDD22;
  --highlight-accent: #D0DE1E;
  --primary-action-color: #FBB200;
  --secondary-action-color: #848484;
  --strong-content-color: #575757;
  --primary-content-color: #848484;
  --background-color: #FCFCFC;
  --input-highlight: #4199FF;
  --input-hover: #2179DF;
  --primary-action-color-hover: #EAA600;
  --missing-color: #FF0036;
  --vendor-color: #3F73AE;
}

a.link{
  color: var(--input-highlight) !important;

}

a.link b{
  font-weight: 600;
}

.action-text{
  color:var(--primary-action-color);
  font-weight: 600;
  cursor: pointer;

}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: var(--background-color);
  /* margin-top: 50px; */
  position: absolute;
  overflow: auto;
  min-height: 100%;
  width: 100%;

  /* height: 100%; */
}



input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}



input[type="radio"] {
  position: absolute;
  opacity: 0;
  -moz-opacity: 0;
  -webkit-opacity: 0;
  -o-opacity: 0;
}

input[type="radio"] + label {
  position: relative;
  padding: 0 0 0 25px;
  font-size: 15px;
  line-height: 25px;
}

input[type="radio"] + label:before {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 0px;
  height: 20px;
  width: 20px;
  background: white;
  border: 1px solid var(--primary-content-color);
  border-radius: 50%;
}

/*input[type="radio"] + label:hover:before{
  background: #eee;
}*/

input[type="radio"]:checked + label:before {
  background: var(--primary-action-color);
  border: 1px solid var(--primary-action-color);

}

input[type="radio"]:checked + label:after {
  content: "";
  display: block;
  position: absolute;
 	top: 11px;
	left: 6px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

a{
  color: inherit;
}

a:hover{
  outline: none;
  text-decoration: none;
  color: inherit;
}

a:visited{
  outline: none;
  text-decoration: none;
  color: inherit;
}

a:active{
  outline: none;
  text-decoration: none;
  color: inherit;
}

a:enabled {
  outline: none;
  text-decoration: none;
  color: inherit;
}

a::selection{
  outline: none;
  text-decoration: none;
  color: inherit;
}


textarea {
    resize: none;
}

img.icon{
  display: inline-flex;
}
