
.special-image{
  display: inline-block;
  vertical-align: top;
}

.special-image .image-wrapper{
  width: 427px;
  max-width: 100%;
  position: relative;
  padding: 8px;
}

.special-image .image {
  width: 411px;
  max-width: 100%;
  border: none;
  outline: none;
}

.special-image .image:hover {
  border: none;
  outline: none;
}

.special-image .caption{
  font-weight: 600;
  font-size: 13px;
  color: var(--primary-content-color);
  letter-spacing: -0.13px;
  margin-top: 10px;
  width: 100%;
  text-align: center;
}
