


.checkout-container{
  /* Rectangle 3: */
  /* box-shadow: 0 1px 10px 0 rgba(0,0,0,0.03);
  border-radius: 5px; */
  width: 996px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: 112px;
  margin-bottom: 110px;
}

.checkout-details{
  padding-top: 1px;
  width: 655px;
  display: inline-block;
  padding-right: 20px;
}

.checkout-details .checkout-header{
  margin-top: 28px;
  font-family: "Open Sans"; font-weight: 600;
  font-size: 20px;
  color: var(--primary-content-color);
}

.checkout-details .sub-header{
  opacity: 0.5;
  font-family: "Open Sans";
  font-size: 15px;
  color: var(--primary-content-color);;
  letter-spacing: -0.21px;
}

.checkout-details .booking{
  width: 220px;
  display: inline-block;

}

.checkout-details .booking, .checkout-details .contact{
  font-size: 15px;
  color: var(--primary-content-color);
  letter-spacing: -0.21px;
  line-height: 24px;
}

.checkout-details .booking .change, .checkout-details .contact .change, .checkout-details .section .change{
  font-weight: 700;
  font-size: 13px;
  color: var(--primary-action-color);
  letter-spacing: -0.19px;
  cursor: pointer;
}

.checkout-details .booking .subheader, .checkout-details .contact .subheader,  .checkout-details .sharing .subheader{
  font-size: 15px;
  color: var(--strong-content-color);
  letter-spacing: -0.21px;
  font-weight: 600;
}

.checkout-details .contact, .checkout-details .booking{
 display: inline-block;
 width: 220px;
 vertical-align: top;
}

.checkout-details .booking{
  border-right: 1px solid rgba(0,0,0,0.08);
  margin-right: 39px;
}

.checkout-details .booking img{
  vertical-align: top;
  margin-top: 5px;
  display: inline-flex;
}


.checkout-details .questions{
  font-family: "Open Sans"; font-weight: 600;
  font-size: 15px;
  color: var(--strong-content-color);
  letter-spacing: -0.21px;
  margin-bottom: 8px;
}

.checkout-details .questions.payment{
  margin-bottom: 0;
  margin-top: 20px;
}

.checkout-details .venue-questions .questions{
  margin-top: 20px;
}


.checkout-details .section .StripeElement{
  margin-bottom: 2px;
}

.checkout-details .address.text-field{
  width: 100%;
}

.my-autocomplete-container{
  z-index: 10;
  width: 100%;
  position: absolute;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 10px 17px 0 rgba(0,0,0,0.30), 0 0 0 1px rgba(0,0,0,0.10);
}

.venue-questions input[type="radio"] + label{
  color: var(--primary-content-color);
  font-size: 15px;
  font-weight: normal;
  margin-right: 10px;
  line-height: 20px;
  cursor: pointer;
}

.venue-questions input[type="radio"] + label::before{
  top: 0;
}

.venue-questions input[type="radio"] + label::after{
  top: 6px;
}

textarea.text-field{
  border: 1px solid rgba(0,0,0,0.17);
  border-radius: 3px;
  color: var(--primary-content-color);
  outline: none;
  padding-left: 12px;
  -webkit-box-shadow: 0 0;
  box-shadow: 0 0;
  background-color: transparent;
  height: 90px;
  line-height: inherit;
}

textarea.text-field:focus{
  border: 1px solid var(--input-highlight);
  outline: none;
  box-shadow: 0 0;
}

textarea.text-field::placeholder{
  opacity: 0.6;
  font-size: 13px;
  color: var(--primary-content-color);
  background-color: transparent;
  letter-spacing: 0;
}

.checkout-details .text-field.credit-card .little-card{
  height: 24px;
  margin-top: 2px;
  vertical-align: top;
  margin-right: 7px;
}

.checkout-details .text-field.credit-card{
  padding-left: 6px;
  margin-top: 10px;
  margin-bottom: 2px;
}

.checkout-details .disclaimer.cc-disclaimer{
  text-align: left;
  margin-bottom: 10px;
}

.checkout-details .text-field.credit-card .ending.text{
  color: var(--strong-content-color);
  margin-left: 0px;

}

.checkout-details .text-field.credit-card .text{
  margin-left: 86px;
  display: inline-block;
}

.checkout-details .text-field.credit-card .change{
  color: var(--primary-action-color);
  display: inline-block;
  float: right;
  padding-right: 6px;
  font-weight: 700;
  cursor: pointer;
}

.checkout-details .booking .text{
  margin-left: 11px;
}

.checkout-details .other{
  width: 357px;
  margin-top: 29px;
}


.checkout-details .section{
  /* border-top: 1px solid rgba(87, 87, 87, .1); */
  margin-bottom: 44px;
  padding: 21px;
  margin-top: 9px;
}

.checkout-details .checkbox-section label{
  width: 140px;
}

.checkout-details .time input{
  border: 1px solid rgba(0,0,0,0.17);
  border-radius: 3px;
  height: 30px;
  color: var(--primary-content-color);
  outline: none;
  padding-left: 12px;
  box-shadow: 0 0;
  background-color: transparent;
  margin-bottom: 4px;
  width: 168px;
}


.checkout-details .time input:focus{
  outline: none;
  box-shadow: 0 0;
}

.checkout-details .time.open input, .checkout-details .time.open input:focus{
  border-radius: 3px 3px 0 0;
  outline: none;
  box-shadow: 0 0;
}


.order{
  position: absolute;
  right: 0;
  top: 0;
  bottom:0;
  width: 320px;
  padding: 19px;
  padding-top: 42px;
}

.order .wrap{
  position: sticky;
  top:0;
  padding-top: 29px;
}

.order .summary{
  border-top: 1px solid rgba(87, 87, 87, .1);

}

.checkout-container .disclaimer{
  opacity: 0.8;
  font-family: "Open Sans";
  font-size: 12px;
  color: var(--primary-content-color);
  text-align: center;
  margin-bottom: 20px;
}

.summary .summary-header{
  font-family: "Open Sans";
  font-size: 19px;
  color: var(--strong-content-color);
  margin-bottom: 4px;
  margin-top: 20px;
}

.summary .lineItem, .recurring-prices .lineItem{
  opacity: 0.6;
  font-family: "Open Sans";
  font-size: 13px;
  margin-bottom: 3px;
  color: var(--strong-content-color);
}

.help{
  margin-top: 30px;
  text-align: center;
}

.help .lineItem{
  opacity: 0.6;
  font-family: "Open Sans";
  font-size: 13px;
  margin-bottom: 3px;
  color: var(--strong-content-color);
}

.summary .price, .recurring-prices .price{
  float: right;
}

.order .total{
  /* Rectangle 7 Copy: */
  border-top: 1px solid rgba(87, 87, 87, .1);
  padding-top: 8px;
  margin-top: 5px;
}

.order .total .cost, .bottom-order .cost{
  font-family: "Open Sans";
  font-size: 15px;
  color: var(--primary-content-color);
}

.order .total .price, .bottom-order .price{
  font-family: "Open Sans"; font-weight: 700;
  font-size: 15px;
  color: var(--primary-content-color);
  float: right;
}

.bottom-order .price{
  float: none;
}

.custom-button .loader-wrapper{
  position: absolute;
  right: 14px;
  top: 3px;
}

.bottom-order .custom-button{
  width: 280px;
  margin-top: 0;
  margin-bottom: 32px;
  margin-right: 20px;
  height: 44px;
  float: none;
  line-height: 44px;
  font-size: 17px;
}

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  padding: 8px 14px;
  /* box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px; */
  border: 1px solid rgba(0,0,0,0.17);
  border-radius: 3px;

  background: white;
}

.StripeElement--focus {
  border: 1px solid var(--input-highlight);
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.checkout-container .timezone {
  color: var(--primary-content-color);
  font-size: 13px;
  margin-left: 12px;
}
