/* Rectangle 5: */

.search-box{

  display: inline-block;
  position: relative;
  float: right;
  margin-right: 10px;

}

.search-box .text-field{
  width: 200px;
  background: transparent;
  padding-left: 32px;
  border: 1px solid rgba(132, 132, 132, 0.5);

}



@media (max-width: 1340px) {
  .powerup-page .search-box .text-field {
    width: 180px;
  }
}

@media (max-width: 1310px) {
  .powerup-page .search-box .text-field {
    width: 160px;
  }
}
