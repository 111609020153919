
.vendor-dash  .powerup{
  height: 40px;
  width: 100%;
  margin-top: 8px;
  /*padding: 10px;*/
  display: flex;
}


.vendor-dash .powerup .powerup-image-container{
  height: 40px;
  width: 40px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, .03);
  border-radius: 4px;
  display: inline-block;
  cursor: default;
  margin-top: 0;
}

.vendor-dash .powerup .powerup-image-container .powerup-image{
  display: inline-block;
  height: 40px;
}

.vendor-dash .powerup .powerup-details{
  width: 100%;
  line-height: 40px;
  height: 40px;
  display: block;
}

.vendor-dash .powerup .powerup-details.qty{
  line-height: normal;
  height: 20px;
}

.vendor-dash .powerup .quantity{
  width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: none;
}

.vendor-dash .powerup .quantity.qty{
  opacity: 0.9;
  font-family: "Open Sans";
  font-size: 13px;
  color: var(--primary-content-color);
  display: block;
}

.vendor-dash .powerup:hover .quantity.qty{
  display: none;
}

.vendor-dash .powerup:hover .quantity.qty.included{
  display: block;
}

.vendor-dash .powerup .powerup-wrapper{
  height: 40px;
  display: inline-block;
  flex: 1;
  margin-left: 10px;
}


.vendor-dash .powerup .powerup-name{
  display: inline-block;
  font-size: 15px;
  overflow: hidden;
  font-family: "Open Sans"; font-weight: 600;
  color: var(--strong-content-color);
  cursor: default;
  max-width: 140px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vendor-dash .powerup .hovered{
  display: none;
}

.vendor-dash .powerup:hover .hovered{
  display: block;
}

.vendor-dash .powerup .change{
  font-family: "Open Sans"; font-weight: 600;
  font-size: 13px;
  color: var(--primary-action-color);
  cursor: pointer;
}

.vendor-dash .powerup .remove{
  font-family: "Open Sans"; font-weight: 600;
  font-size: 13px;
  color: #FF5200;
  cursor: pointer;
}

.powerup .powerup-price{
  float: right;
  font-size: 13px;
  overflow: hidden;
  opacity: 0.6;
  color: var(--primary-content-color);
}

.powerup:hover .powerup-price{
  display: none;
}

.powerup:hover .powerup-price.included{
  display: inline-block;
}

.powerup .powerup-price-per{
  display: none;
}
.powerup:hover .powerup-price-per{
  display: block;
  float: right;
  font-size: 13px;
  overflow: hidden;
  opacity: 0.6;
  color: var(--primary-content-color);
}

/*.powerup:hover{
  display: block;
  position: relative;
}*/

.powerup:hover .powerup-details{
  line-height: normal;
  height: 20px;
}

.powerup:hover .powerup-details.included{
  line-height: 40px;
  height: 40px;
}

.powerup:hover .powerup-details.qty.included{
  line-height: normal;
  height: 20px;
}

.event-view .notes {
  color: var(--primary-content-color);
  margin-top: 6px;
  margin-left: 50px;
}

.vendor-dash .event-view .booking .text{
  margin-left: 0;
  max-width: 219px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.vendor-dash .event-view .booking .text b{
  font-weight: 400;
  color: var(--strong-content-color);
}

.vendor-dash .event-view .booking .text.location{
  margin-left: 0;
  max-width: 219px;
  text-overflow: inherit;
  overflow: visible;
  white-space: normal;
}

.vendor-dash .event-view .poc{
  margin-top: 30px;
}

.vendor-dash .event-view .contact{
  width: 300px;
}

.event-view .checkout-details .booking{
  width: 250px;
}

.vendor-dash .event-view .earn{
  position: absolute;
  right: 0;
  top: 2px;
  font-size: 15px;
  color: var(--primary-content-color);
}

.vendor-dash .event-view .paid{
  position: absolute;
  right: 0;
  top: 22px;
  font-size: 15px;
  color: var(--primary-content-color);
}

.vendor-dash .event-view .start-time{
  position: absolute;
  right: 200px;
  top: 0;
}

.vendor-dash .event-view .total-earnings{
  position: absolute;
  right: 0;
  font-size: 15px;
  color: var(--strong-content-color);
}
.vendor-dash .event-view .total-container{
  height: 21px;
  width: 100%;
  position: relative;
}

.vendor-dash .event-view .total-earnings b{
  font-weight: 600;
}
