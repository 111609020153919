

a.custom-button{
  background: #FBB200;
  border-radius: 3px;
  cursor: pointer;
  font-family: "Open Sans"; font-weight: 600;
  font-size: 15px;
  color: white;
  width: 166px;
  line-height: 30px;
  text-align: center;
  float: right;
  transition: .2s ease-out;
  margin-top: 11px;
  display: inline-block;
  position: relative;
  padding: 0;
}

.custom-button.vendor{
  background: var(--vendor-color);
}

.custom-button.vendor.disabled{
  background-color: rgba(63, 115, 174, .4);
}

a.custom-button:hover, a.custom-button:focus {
  background: #EAA600;
}

a.custom-button:active{
  background: #AE7C01;
}

.custom-button:hover, .custom-button:focus{
  background: #EAA600;
  outline: none;
}

.custom-button:active{
  background: #AE7C01;
}

.custom-button.full{
  width: 100%;
  float: none;
  display: block;
}

.custom-button.vendor:hover, .custom-button.vendor:focus{
  background: #27578E;
  outline: none;
}

.custom-button.vendor.disabled:hover{
  background-color: rgba(63, 115, 174, .4);
}

.custom-button.vendor:active{
  background: #27578E;
}

a.secondary-button{
  border: 1px solid rgba(132,132,132, 0.6);
  border-radius: 3px;
  float: right;
  padding: 0;
  padding-right: 16px;
  padding-left: 16px;
  height: 30px;
  line-height: 28px;
  font-family: "Open Sans"; font-weight: 600;
  margin-top: 11px;
  font-size: 15px;
  color: var(--primary-content-color);
  cursor: pointer;
  text-align: center;
  display: inline-block;
  background: transparent;
  text-align: center;
}

a.secondary-button:hover, a.secondary-button:focus{
  background: rgba(132,132,132,0.10);
}

a.secondary-button:active{
  background: rgba(132,132,132,0.30);
}

.secondary-button:hover, .secondary-button:focus{
  background: rgba(132,132,132,0.10);
  outline: none;

}

.secondary-button:active{
  background: rgba(132,132,132,0.30);
}

.secondary-button.accepted{
  border: 1px solid rgba(132,132,132, 0.2);
  cursor: default;
  font-weight: normal;
}

.secondary-button.accepted:hover{
  background: transparent;

}

.custom-button{
  background: #FBB200;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-family: "Open Sans"; font-weight: 600;
  font-size: 15px;
  color: white;
  width: 166px;
  line-height: 30px;
  text-align: center;
  float: right;
  transition: .2s ease-out;
  margin-top: 11px;
  display: inline-block;
  position: relative;
  padding: 0;

}

.custom-button.disabled, a.custom-button.disabled{
  /* Rectangle 11: */
  color: rgba(164, 116, 1, 0.2);
  background: rgba(251, 178, 0, 0.2);
  cursor: not-allowed;
}

.custom-button.large{
  float: none;
  line-height: 44px;
  display: inline-block;
  margin-top: 0;
}


.secondary-button{
  border: 1px solid rgba(132,132,132, 0.6);
  border-radius: 3px;
  float: right;
  padding: 0;
  padding-right: 16px;
  padding-left: 16px;
  height: 30px;
  line-height: 28px;
  font-family: "Open Sans"; font-weight: 600;
  margin-top: 11px;
  font-size: 15px;
  color: var(--primary-content-color);
  cursor: pointer;
  display: inline-block;
  background: transparent;
  text-align: center;
}

.secondary-button.disabled{
  opacity: 0.5;
  cursor: not-allowed;
}

.secondary-button.disabled:hover{
  background: transparent;
}

.secondary-button.full{
  width: 100%;
  float: none;
  display: block;
}
/*.text-field{
  border: 1px solid rgba(132,132,132, 0.6);
  border-radius: 3px;
  height: 30px;
  line-height: 28px;
  padding-left: 12px;
  color: var(--primary-content-color);
}*/

.tertiary-button{
  color: var(--primary-action-color);
  cursor: pointer;
  font-size: 15px;
  display: inline-block;
  line-height: 30px;
  margin-top: 11px;
}

.tertiary-button:hover, .tertiary-button:focus {
  color: #EAA600;
}



.tertiary-button:active{
  color: #AE7C01;
}

.remove{
  font-family: "Open Sans"; font-weight: 600;
  font-size: 13px;
  color: #FF5200;
  cursor: pointer;
}

.unselected-button {
  text-align: center;
  opacity: 0.75;
  font-size: 18px;
  color: #484848;
  letter-spacing: 0;
  border-radius: 3px;
  cursor: pointer;
  line-height: 32px;
  transition: .2s ease-out;
}

.custom-button.day, .unselected-button.day{
  margin-top: 10px;
  margin-right: 39px;
  width: 132px;
  display: inline-block;
  float: none;
  font-size: 18px;
  line-height: 32px;
  font-weight: normal;
}



.unselected-button:hover{
  background: #F2F2F2;
}
