.tldr{
  font-family: "Open Sans";
  font-size: 13px;
  color: var(--primary-content-color);
  margin-bottom: 125px;
  margin-top: 12px;
  width: 561px;
}

.tldr ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.tldr ul > li {
  position: relative;
  padding-left: 10px;
}

.tldr ul > li:before {
  content: "•";
  position: absolute;
  left: 0;
}
