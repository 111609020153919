
.dashboard{
  width: 996px;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 16px; */
  padding-top: 40px;
  padding-bottom: 100px;
}

.dashboard .static-page-subheader{
  width: 996px;
  margin-left: auto;
  margin-right: auto;
}

.dashboard th{
  color: var(--strong-content-color);
  font-family: "Open Sans"; font-weight: 700;
}

.dashboard td{
  color: var(--strong-content-color);
  font-family: "Open Sans";
}

.dashboard .view{
  color: var(--highlight-color);
}


.dashboard .event-list {
  margin-left: -30px;
}

.dashboard .rebook{
  position: relative;
  display: inline-block;
  margin-left: 12px;
}

.dashboard .rebook-icon{
  position: absolute;
  left: 12px;
  bottom: 6px;
}

.dashboard .secondary-button.with-icon{
  padding-left: 38px;
}

.dashboard .view-list{
  position: absolute;
  bottom: 22px;
  width: 141px;
}

.wishlist .delete-text{
  font-size: 13px;
  color: #FF5200;
  position: absolute;
  cursor: pointer;
  bottom: 28px;
  right: 16px;
}

.shared{
  position: absolute;
  bottom: 28px;
  left: 174px;
}

.shared .text{
  /* Shared: */
  font-size: 13px;
  color: var(--primary-content-color);
  letter-spacing: -0.19px;
  line-height: 22px;
  margin-left: 4px;
  display: inline-block;
}
