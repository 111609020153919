
.not-found{
  height: 450px;
  width: 996px;
  margin-top: 125px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-bottom: 629px;
}

.not-found .content{
  width: 506px;
  padding-left: 41px;
  padding-top: 58px;
  display: inline-block;
  vertical-align: top;
}

.not-found .title{
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 28px;
  color: var(--strong-content-color);
  letter-spacing: -0.36px;
  margin-bottom: 15px;
}


.not-found .text{
  font-family: "Open Sans";
  font-size: 15px;
  color: var(--primary-content-color);
  padding-bottom:10px;
}

.not-found .text p{
  margin-bottom: 15px;
}

.not-found .text p a{
  color: var(--input-highlight);
}

.not-found .text p b{
  color: var(--strong-content-color);
  font-weight: 600;
}

.not-found .image-wrapper{
  height: 311px;
  width: 427px;
  display: inline-block;
  margin-left: 20px;
  margin-top: 58px;
  vertical-align: top;
}

.not-found .image {
  height: 295px;
  width: 411px;
  margin: 8px;
  border: none;
  outline: none;
}

.not-found .image:hover {
  border: none;
  outline: none;
}
