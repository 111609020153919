.powerup-page{
  position: relative;
  /* padding-top: 102px; */
  margin-bottom: 108px;
}

.powerup_list .sublist ul {
  padding: 0;
}

h1 {
  text-transform: capitalize;
}

h2 {
  text-transform: capitalize;
}
.powerup_list .sublist  li {
  padding: 0;
  margin-left: 50px;
}

.powerup_list .title-wrapper{
  padding-left: 50px;
}

.powerup_list{
  min-height: 600px;
}

.powerup_list .sub{
  /* opacity: 0.5; */
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 24px;
  color: var(--primary-content-color);
  margin-bottom: 12px;
  letter-spacing: 0;
  margin-left: 50px;
  padding-top: 28px;
  padding-bottom: 5px;
}

.powerup_list .nothing-here{
  color: var(--primary-content-color);
  padding-left: 50px;
}
.powerup-page .sidebar{
  width: 198px;
  display: inline-block;
  position: fixed;
  width: 240px;
  height: 100%;
  padding-top: 101px;
  text-align: right;
  padding-right: 35px;
  background-color: var(--background-color);
  z-index: 5;
  text-transform: capitalize;
}

.footer.simple{
  padding-left: 240px;
}


.powerup_list{
  width: auto;
  position: relative;
  /* overflow-y: scroll; */
  padding-left: 195px;
  padding-top: 35px;
  /* z-index: 1; */
  transition: .2s ease-out;

}


@media (max-width: 1350px) {

  .powerup_list .sublist li{
    margin-left: 28px;
  }

  .powerup_list .sublist ul{
    margin-left: 22px;
  }

  .powerup-page .step{
    margin-left:90px;
  }

  .powerup_list {
    padding-left: 165px;
  }

  .footer.simple{
    padding-left: 210px;
  }

  .powerup-page .sidebar{
    width: 210px;
  }
}
@media (max-width: 1280px) {
  .powerup-page .sidebar {
    position: absolute;
    top: 52px;
    bottom: 0;
    height: calc(100vh - 50px);
    left: 0;
  }

  .powerup-page .step{
    position: absolute;
    top: 52px;
    left: 0;
  }
}
/* @media (min-width: 1280px) {



} */

.sidebar .content{
  border-right: 1px solid rgba(0,0,0,0.08);
  padding-right: 15px;
}

.step{
  position: fixed;
  margin-top: 30px;
  margin-left: 120px;
  font-size: 28px;
  font-weight: 200;
  opacity: 0.6;
  color: var(--primary-content-color);
  z-index: 10;
}

.powerup_list .sublist li{
  display:inline-block;
  padding:0;
}

.sidebar .sub_category{
  color: var(--strong-content-color);
  font-size: 15px;
  letter-spacing: -0.21px;
  line-height: 30px;
  cursor: pointer;
}



.sidebar .sub_category:hover{
  color: var(--primary-content-color)
}
.sidebar .sub_category.selected{
  color: var(--highlight-color);
  font-weight: bold;
}

.categories{
  position: relative;
  height: 52px;
  width: 100%;
  min-width: 830px;
  z-index: 20;
  background-color: var(--background-color);
}

.categories .bottom{
  width:100%;
  height: 1px;
  position: absolute;
  bottom:0;
  background-color: rgba(0,0,0,0.08);
  z-index: 60;
}

.categories-wrapper{
  position: absolute;
  position: sticky;
  top: 64px;
  width: 100%;
  z-index: 23;
  transition: .2s ease-out;
}

.categories-wrapper .custom-button{
  width: 140px;
}

a.tab{
  position:relative;
  /*border: 1px solid #c2e1f5;*/
  line-height: 52px;
  font-size: 15px;
  padding-left: 15px;
  padding-right: 15px;
  min-width: 90px;
  letter-spacing: -0.21px;
  /*text-decoration: none;*/
  color: var(--primary-content-color);
  /*margin-left:1.6em;
  margin-right:1.6em;*/
  display: inline-block;
  text-align: center;
  /* border-right: 1px solid; */
  /* -webkit-border-image:
  -webkit-gradient(linear, 0 0, 0 100%, from(black), to(rgba(0, 0, 0, 0))) 1 100%;
  -webkit-border-image:
    -webkit-linear-gradient(black, rgba(0, 0, 0, 0)) 1 100%;
  -moz-border-image:
    -moz-linear-gradient(black, rgba(0, 0, 0, 0)) 1 100%;
  -o-border-image:
    -o-linear-gradient(black, rgba(0, 0, 0, 0)) 1 100%; */
  /* border-image: */
  /* Rectangle 13: */
  /*background-image: linear-gradient(-173deg, #99DA55 0%, #CBD73A 100%);*/
}

.gradient-divider{
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(132, 132, 132, 0.3), rgba(0, 0, 0, 0));
  vertical-align: top;
  height: 50px;
  width: 1px;
  display: inline-block;
}

a.tab.first{
  min-width: 100px;
  padding-left: 18px;
}

/* a.tab.middle::after{
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  width: 0;
  height: 0;
  border-top: 26px solid transparent;
  border-bottom: 26px solid transparent;
  border-left: 13px solid var(--background-color);
  z-index: 50;
}

a.tab.middle::before{
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 0;
  height: 0;
  border-top: 26px solid var(--background-color);
  border-bottom: 26px solid var(--background-color);
  border-left: 13px solid transparent;
  z-index: 50;
}

a.tab.last::after{
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  width: 0;
  height: 0;
  border-top: 26px solid var(--background-color);
  border-bottom: 26px solid var(--background-color);
  border-left: 13px solid transparent;
  z-index: 50;
}

a.tab.middle:hover::after{
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  width: 0;
  height: 0;
  border-top: 26px solid transparent;
  border-bottom: 26px solid transparent;
  border-left: 13px solid #f1f1f0;
  z-index: 52;
}

a.tab.middle:hover::before{
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 0;
  height: 0;
  border-top: 26px solid #f1f1f0;
  border-bottom: 26px solid #f1f1f0;
  border-left: 13px solid transparent;
  z-index: 52;
} */


/*a.tab:hover{
  font-family: "Open Sans"; font-weight: 700;
}*/

.tab .checkmark{
  /* position: absolute;
  top: 16px;
  right: 13px; */
  margin-left: -2px;
  z-index: 55;
}


a.tab.selected{
  background-image: linear-gradient(173deg, #99DA55 0%, #CBD73A 100%);
  /* background-image: linear-gradient(to bottom, var(--highlight-color) 0%,  var(--highlight-accent) 100%); */
  color: white;
  text-decoration: none;
  font-family: "Open Sans"; font-weight: 700;
  z-index: 10;
}

a.tab.selected:focus{
  outline: none;
}


.tab:hover, .tab:focus{
  background-color: #f1f1f0;
  text-decoration: none;
}

.tab.hover{
  background-color: #f1f1f0;
  text-decoration: none;
}

.reg-powerup-container .add{
  cursor: pointer;
  /* position: absolute; */
  /* right: 10px;
  bottom: 7px; */
  /* right: 12px;
  bottom: 25px; */
}

.reg-powerup-container .in-cart{
  position: absolute;
  /* right: 10px;
  bottom: 8px; */
  right: 12px;
  bottom: 25px;
}



.powerup-modal .availability-dropdown .my-small-caret{
  right: 7px;
}

.powerup-modal .options-required{
  margin-left: 21px;
  color: var(--missing-color);
  font-size: 12px;
  margin-bottom: -5px;
}

.powerup-modal .for{
  margin-left: 4px;
}

.package-modal .availability-dropdown .my-small-caret{
  right: 12px;
}


.reg-powerup-container .heart{
  /* position: absolute;
  right: 44px;
  bottom: 40px;
  height: 24px;
  width: 24px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px; */
  height: 32px;
  width: 32px;
}

.reg-powerup-container .availability-dropdown{
  /* position: absolute;
  right: 4px;
  bottom: 28px; */
}

.availability-badge{
  padding: 3px;
  border-radius: 3px;

}

.availability-badge .my-small-caret{
  right: 4px;
}

.availability-badge:hover{
  background-color: rgba(0,0,0, 0.1)
}

.availability-badge-container{
  width: 41px;
  border-radius: 3px;
}

.reg-powerup-container .inactive{
  position: absolute;
  right: 18px;
  top: 36px;
  font-size: 40px;
  font-weight: bold;
  color: white;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
}

.availability-dropdown .slot{
  display: inline-block;
  margin-left: 12px;
  margin-bottom: 12px;
  cursor: pointer;
}

.availability-dropdown .explaination{
  margin-bottom: 12px;
  margin-left: 12px;

  /* width: 200px; */
  color: var(--strong-content-color);
}

.availability-dropdown .not-available{
  margin-top: 80px;
  margin-left: 12px;
  font-size: 16px;
  text-align: center;
  color: var(--primary-content-color);
}

.availability-dropdown .explaination b{
  font-weight: 600;
}

.availability-dropdown .slot .time{
  height: 30px;
  width: 75px;
  line-height: 30px;
  text-align: center;
  background-color: var(--primary-action-color);
  color: white;
  font-weight: 600;
  border-radius: 3px;
}

.availability-dropdown .slot .duration{
  font-size: 12px;
  color: var(--primary-content-color);
}




.reg-powerup-container .heart img, .reg-package-container .heart img{
  height: 32px;
  width: 32px;
}

.reg-package-container .heart img{
  height: 32px;
  width: 32px;
}

.powerup-modal .heart.shadow, .corner-heart .heart.shadow{
  background-color: rgba(132, 132, 132, 0.1);
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
}

.heart.shadow{
  background-color: rgba(132, 132, 132, 0.1);
}

.heart img.in-wishlist {
  position: absolute;
  left: 0px;
  top: 5px;
  height: 20px !important;
  width: auto;
}

.reg-powerup-container .heart .dropdown-menu, .reg-package-container .heart .dropdown-menu{
  top: 32px;
  right: -2px;
  left: auto;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-bottom: 2px !important;
  z-index: 1000;
}

.availability-dropdown .dropdown-menu{
  top: 28px;
  right: -7px;
  left: auto;
  /* border-top-left-radius: 0;
  border-top-right-radius: 0; */
  /* padding-bottom: 2px !important; */
  padding: 20px !important;
  height: 300px;
  overflow: auto;
  width: 318px;
}

.powerup-modal .availability-dropdown .dropdown-menu, .package-modal .availability-dropdown .dropdown-menu{
  top: 31px;
  right: 1px;
}



.powerup-modal .heart, .package-modal .heart{
  height: 32px;
  width: 32px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}


.powerup-modal .heart img{
  height: 32px;
}

.package-modal .heart .dropdown-menu{
  top: 31px;
  right: 1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-bottom: 2px !important;
}

.powerup-modal .heart .dropdown-menu, .package-modal .corner-heart .heart .dropdown-menu{
  top: 100% !important;
  right: 1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-bottom: 2px !important;
}

.heart .dropdown-menu li{
  margin-left: 0;
  display: block;
}

.heart .dropdown-menu>li>a{
  padding-top: 0;
  padding-bottom: 0;
  color: var(--strong-content-color);
  max-width: 260px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
}

.heart .dropdown-menu .divider{
  margin-top: 4px;
  margin-bottom: 4px;
}

.heart .choose{
  margin-left: 22px;
  color: rgba(132, 132, 132, 0.6);
  line-height: 28px;
  font-size: 13px;
  margin-top: 1px;
}

.regular .edit{
  cursor: pointer;
  position: absolute;
  right: 67px;
  bottom: 2px;
  font-size: 20px;
}

.regular .soldout{
  position: absolute;
  left: 7px;
  bottom: 5px;
}


.regular {
  overflow: hidden;
  width: 320px;
  height: 280px;
  display: inline-block;
  /* margin-bottom: 30px; */
  position: relative;
}

.regular .image {
  height: 208px;
  width: 329px;
  margin-top: -5px;
  cursor: pointer;
  border-bottom: 1px solid rgba(0,0,0, 0.1);
}

.regular .blank{
  height: 208px;
  width: 329px;
  margin-top: -5px;
  background: white;
  border-bottom: 1px solid rgba(0,0,0, 0.1);

}

.regular .name {

  text-align: left;
  color: var(--strong-content-color);
  font-family: "Open Sans"; font-weight: 600;
  font-size: 13px;
  letter-spacing: -0.19px;
  margin-left: 8px;
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
  margin-top: 5px;
  cursor: pointer;
}

.regular .name:active{
  outline: none;
  text-decoration: none;
  color: inherit;
}

.reg-powerup-container .price {
  text-align: left;
  font-size: 16px;
  color: var(--primary-content-color);
  font-weight: normal;
  /* margin-left: 7px; */
  opacity: 0.6;
  letter-spacing: -0.19px;
}

.reg-powerup-container{
  position: relative;
  height: 280px;
}

.reg-package-container{
  position: relative;
}

.reg-powerup-container li{
  margin: 0;
}

.reg-powerup-container .DayPicker{

}

.calendar-wrapper{
  position: absolute;
  left: 188px;
  top: 192px;
  z-index: 500;
}

.calendar-wrapper.right{
  left: auto;
  right: 0;
}

.big-powerup-container{
  position: relative;
}


/* .reg-powerup-container .DateInput{
  display: none;
} */


.powerup-modal{
  box-shadow: 0 22px 49px 0 rgba(0,0,0,0.19);
  margin-top: 100px;
  width: 602px;
  height: 499px;
  border-radius: 5px;

}

.powerup-modal .content-wrapper{
  border-radius: 5px;
  overflow: hidden;
  width: 602px;
  height: 499px;
  position: relative;
  z-index: 2;
}

.powerup-modal .scroll-container{
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  position: relative;
  scrollbar-width: none;
}


.powerup-modal .modal-content .DayPicker{
  position: absolute;
  right: 0;
  top: 260px;
  z-index: 500;
}


a.next-step{
  border: 1px solid rgba(132,132,132, 0.6);
  border-radius: 3px;
  float: right;
  margin-right: 12px;
  margin-top: 11px;
  padding-right: 16px;
  padding-left: 16px;
  height: 30px;
  line-height: 28px;
  font-family: "Open Sans"; font-weight: 600;
  font-size: 15px;
  color: var(--primary-content-color);
  cursor: pointer;
  transition: .2s ease-out;
}

.powerup-backdrop{
  z-index: 2000;
}


.powerup-modal .content-wrapper:after {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  content: "";
  background: linear-gradient(to bottom,
     rgba(255,255,255, 0) 94%,
     rgba(255,255,255, 1) 99%
  );
  pointer-events: none;
}

.powerup-modal .img-container{
  width: 602px;
  height: 202px;
  overflow: hidden;
  position: relative;
}

.powerup-modal .image{
  width: 602px;
  position: absolute;
  top: -25%;
}

.powerup-modal .name{
  /* Waffle Bar: */
  font-family: "Open Sans"; font-weight: 600;
  font-size: 28px;
  color: var(--strong-content-color);
  letter-spacing: -0.4px;
  display: inline-block;
  padding-top: 15px;
}



.powerup-modal .description{
  margin-left: 21.5px;
  margin-right: 21.5px;
  flex: 1;
  /* Whether for actual b: */
  font-family: "Open Sans"; font-weight: 600;
  font-size: 13px;
  color: var(--primary-content-color);
  letter-spacing: -0.4px;
}

.powerup-modal .description p{
  margin-bottom: 13px;
}

.powerup-modal .description ul{
  margin-bottom: 13px;
}

.powerup-modal .description ul ul{
  margin-bottom: 0;
}

.powerup-modal .options {
  margin-top: 11px;
  width: 550px;
  max-width: 100%;
  margin-left: 20px;
}

.powerup-modal .options-head-wrapper{
  margin-left: 21px;
}


.options-header{
  font-size: 16px;
  color: var(--strong-content-color);
  font-weight: 600;
}


.powerup-modal .options .opt{
  width: 250px;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin-bottom: 6px;
}

.powerup-modal .options label{
  font-family: "Open Sans";
  margin: 0;
  font-size: 15px;
  font-weight: normal;
  min-height: 30px;
  line-height: 30px;
  color: var(--primary-content-color);
  letter-spacing: -0.21px;
  white-space: nowrap;
  max-width: 198px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
}

.powerup-modal .options .opt .text-field{
  position: absolute;
  left: 35;
  top: 0px;
  height: 26px;
  width: 200px;
}

.powerup-modal .buttons{
  top: 219px;
  right: 20px;
  position: absolute;
}




.powerup-modal .buttons .available{
  height: 14px;
  display: inline-block;
}

.powerup-modal .buttons .text{
  font-size: 13px;
  color: var(--primary-content-color);
  letter-spacing: -0.19px;
  display: inline-block;
  margin-left: 4px;
  /* margin-right: 15px; */
}

.powerup-modal .add{
  height: 34px;
  display: inline-block;
  background-color: var(--primary-action-color);
  color: white;
  border-radius: 5px;
  font-family: "Open Sans"; font-weight: 600;
  font-size: 15px;
  letter-spacing: -0.21px;
  cursor: pointer;
}

.powerup-modal .custom-button{
  height: 34px;
  margin-top: 0;
  border-radius: 5px;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: -0.21px;
  line-height: 34px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 20px;
  padding-left: 20px;
  padding-right: 20px;
  width: fit-content;

}

.powerup-modal .custom-button.diasbled.add-text{
}

.powerup-modal .add:hover, .powerup-modal .add:focus{
  background: #EAA600;
  outline: none;
}

.powerup-modal .add:active{
  background: #AE7C01;
}

.powerup-modal .added{
  background-color: rgba(255, 182, 0, 0.1);
  border-radius: 5px;
  height: 34px;
  line-height: 34px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 20px;
  padding-left: 8px;
  padding-right: 20px;
  text-align: center;
}

.powerup-modal .added .text{
  font-family: "Open Sans"; font-weight: 600;
  font-size: 15px;
  color: var(--primary-action-color);
  letter-spacing: -0.21px;
}


.powerup-modal .confirm-change {
  height: 34px;
  line-height: 34px;
  position: absolute;
  top: 219px;
  right: 20px;
}

.powerup-modal .confirm-change .text{
  margin-left: 10px;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 15px;
  color: var(--strong-content-color);
  letter-spacing: -0.21px;
}


.powerup-modal .icon{
  margin-left: 5px;
  height: 32px;
}

.powerup-modal .availability{
  height: 39px;
  line-height: 37px;
  /* position: absolute;
  top: 219px;
  right: 20px; */
  background-color: var(--primary-action-color);
  color: white;
  display: inline-block;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 5px;
  /* border: 1px solid var(--strong-content-color); */
}

.powerup-modal .availability:hover, .powerup-modal .availability:focus{
  background: #EAA600;
  outline: none;
}

.powerup-modal .availability.calendar{
  background: #EAA600;
  outline: none;
  /* background: rgba(132,132,132,0.20); */
}

.powerup-modal .availability img{
  margin-bottom: 6px;
}

.powerup-modal .availability .text{
  margin-left: 10px;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 15px;
  color: white;
  letter-spacing: -0.21px;
}

.powerup-modal .cart-icon{
  margin-left: 5px;
  height: 34px;
}

.powerup-modal .add-text{
  margin-left: 6px;
  margin-right: 15px;
  line-height: 34px;
}

.powerup-modal .bottom-bar{
  position: relative;
  height: 67px;
  z-index: 9;
  background-color: #FAFBFC;
  box-shadow: 0 -1px 2px 0 rgba(225,225,225,0.50);
  border-radius: 0 0 5px 5px;
}

.powerup-modal .bottom-bar .params{
  padding: 16px;
  font-size: 17px;
  letter-spacing: -0.27px;
  display: flex;
  flex:1;
  color: var(--primary-content-color);
  /* position: absolute; */
  /* top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); */
}

.powerup-modal .action-button{
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 20px;
}

.powerup-modal .action-button .availability-dropdown .dropdown-menu{
  top: auto;
  bottom: 41px;
  right: 0px !important;
}

.powerup-modal .bottom-bar .unavailable-warning{
  /* left: 20px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); */
}

.powerup-modal .bottom-bar .unavailable-warning .title{
  color: var(--strong-content-color);
  font-weight: 600;
  font-size: 18px;
}

.powerup-modal .bottom-bar .unavailable-warning .sub{
  color: var(--strong-content-color);
  font-size: 15px;
}

.powerup-modal .bottom-bar .quantity-input{
  width: fit-content;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  border-radius: 3px;
  height: 37px;
  margin-left: 4px;

}

.powerup-modal .bottom-bar .quantity-input:focus-within{
  border: 1px solid var(--input-highlight);
}

.powerup-modal .bottom-bar .quantity-input input{
  width: fit-content;
  color: var(--primary-content-color);
  padding-left: 8px;
  padding-right: 8px;
  font-size: 16px;
  border: none;
  height: 33px;
  outline: none;
}


.powerup-modal .bottom-bar .quantity-input .text{
  color: var(--primary-content-color);
  padding-left: 8px;
  padding-right: 8px;
  font-size: 16px;
  line-height: 33px;
}

.powerup-modal .bottom-bar .quantity-input .text.warning{
  color: var(--missing-color);

}

.powerup-modal .bottom-bar .quantity-input input.warning{
  color: var(--missing-color);
}

.powerup-modal .bottom-bar .duration-qty{
  width: fit-content;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  border-radius: 3px;
  height: 37px;
  margin-left: 4px;
}

.powerup-modal .bottom-bar .duration-qty .durtext{
  color: var(--primary-content-color);
  padding-left: 8px;
  padding-right: 22px;
  line-height: 33px;
}

.powerup-modal .bottom-bar .duration-qty .dropdown-menu{
  top: 35px;
}


.powerup-modal .bottom-bar .maximum-warning{
  font-size: 14px;
  display: inline-block;
  margin-left: 4px;
}


.powerup-modal .short-includes{
  opacity: 0.6;
  margin-left: 21px;

  font-family: "Open Sans"; font-weight: 600;
  font-size: 13px;
  color: var(--primary-content-color);
  letter-spacing: -0.19px;
}

.params .price{
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 19px;
  color: var(--primary-content-color);
}

.params .emphasis{
  color: var(--strong-content-color);
}

.params .change{
  font-size: 13px;
  font-family: "Open Sans"; font-weight: 700;
  color: var(--primary-action-color);
  cursor: pointer;
}

.welcome-modal{

}

.welcome-backdrop{
  z-index: 9900;
}


.categories-wrapper.first{
  z-index: 9990;

}

.categories.first{
  border: 1px solid rgba(132,132,132,0.22);
  box-shadow: 0 6px 14px 0 rgba(0,0,0,0.08);
}

.categories-wrapper .search-box{
  margin-right: 48px;
  margin-top: 11px;
}

.powerup-modal .close-button{
  position: absolute;
  top: 0;
  left: 21px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: fixed;
  background: white;
  z-index: 10;
  box-shadow: 0 2px 7px 0 rgba(0,0,0,0.12);
  cursor: pointer;
  top: 1.25rem !important;
  font-size: 36px;
}

.powerup-modal .close-button:hover{
  font-size: 40px;
}

.powerup-modal .close-button::after{
    content: '\d7';
    color: var(--primary-content-color);
    position: absolute;
    /* font-size: 36px; */
    text-align: center;
    line-height: 38px;
    width: 40px;
}

.powerup-modal  .staff .title{
  font-size: 15px;
  /* font-weight: 600; */
  color: var(--primary-content-color);
  opacity: 1;
  margin-right: 8px;
}

.powerup-modal  .staff  .num_staff{
  width: 40px;
  padding-left: 8px;
}

.powerup-modal .staff .requirements{
  /* 3 to 7 required. Min: */
  margin-left: 4px;
  font-size: 13px;
  color: var(--primary-content-color);
}

.powerup-modal  .staff{
  margin-left: 21px;
  margin-bottom: 20px;
  margin-top: 8px;

}
