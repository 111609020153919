

.faq .card{
  margin-bottom: 100px;
}


.faq .question {
  font-size: 20px;
  font-weight: 600;
  color: var(--input-highlight);
  cursor: pointer;
}

.faq .question:hover{
  color: var(--input-hover);
}

.faq .section {
  padding-bottom: 15px;
}


.faq .answer {
  font-size: 16px;
  color: var(--primary-content-color);
  padding-left: 20px;
  overflow-y: hidden;
  display: none;
  padding-bottom: 0px;
  padding-top: 10px;
  /* transition: padding-bottom .1s linear, max-height .4s linear; */

}

.faq .answer.show{
  display: block;


  /* transition: max-height .4s linear, padding-bottom .1s linear; */


}
