.navbar-brand {
    float: left;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
    width: 130px;
}

.header {
  width: 100%;
  height: 64px;
  background-color: white;
  position: absolute;
  position: sticky;
  top:0;
  box-shadow: 0 1px 10px 0 rgba(0,0,0,0.03);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  z-index: 50;
  /* min-width: 1280px; */

}

.new .header {
 position: relative; 
 display: flex;
 align-items: center;
 height: 74px;
 z-index: 1;
}

.header.missing{
  position: static;
}

.header .loader-wrapper{
  display: inline-block;
  height: 64px;
  vertical-align: top;
  padding-left: 10px;
  padding-top: 2px;

}

.header .loader{
  display: flex;
  height: 64px;

  align-items: center;
}

.header.simple{
  height: 100px;
  background-color: transparent;
  box-shadow: none;
  position: absolute;
  top:0;
  width: 100%;
}

.header.simple .content{
  width: 996px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

@media (min-width: 768px) {

  .menu {
    display: none;
  }
  .burger {
    display: block;
  }

  /*.header{
    padding: 10px 9vw;

  }*/

}

@media (max-width: 767px) {

  .menu {
    display: block;
  }
  .burger {
    display: none;
  }
  /*.header{
    padding: 10px 3vw;
  }*/
}

@media (max-width: 1382px) {

  .nav-text.nav-links {
    display: none;
  }
  .nav-text.nav-small-links {
    display: inline-block;
    width: 90px
  }
  .header{
    position: absolute;
    position: sticky;
  }
}

@media (min-width: 1382px) {

  .nav-text.nav-links {
    display: inline-block;
  }
  .nav-text.nav-small-links {
    display: none;
    width: 90px

  }

  /* .header{
    position: sticky;
  } */
  /*.header{
    padding: 10px 3vw;
  }*/
}


.header{
  padding-left: 10px;
  padding-right: 12px;

}

.header > .navbar-brand > img {
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
}

.bookbar{
  /* box-shadow: 0 0; */
  border-width: 1px;
  border-style: solid;
  border-color: rgba(132,132,132, 0.3);
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05);
  width: 702px;
  height: 50px;
  /* line-height: 48px; */
  margin-top: 10px;
  float: left;
  z-index: 9001;
  color: var(--primary-content-color);
  position: relative;
  display: flex;
  flex-direction: row;
}

.new .bookbar {
  border-radius: 9999px;
  /* height: 50px;
  line-height: 48px;
  width: 722px; */
  margin-top:0;
  /* display: flex;
  align-items: center; */

}

.bookbar .SingleDatePicker{
  height: 100%;
}

.bookbar.missing{
  border-color: var(--missing-color);
  z-index: 9999;
}

.bookbar .input-missing::placeholder{
  color: var(--missing-color);
}

.bookbar.large {
  height: 56px;
  background: #FFFFFF;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,0.07);
  border: none;
  width: 996px;
}

.bookbar .enter-email{
  padding-top: 14px;
  padding-bottom: 9px;
  margin-top: 14px;
  border-top: 1px solid rgba(0,0,0, 0.08)
}

.eventDetailsModal .enter-email{
  padding-top: 14px;
  padding-bottom: 9px;
  margin-top: 14px;
  border-top: 1px solid rgba(0,0,0, 0.08)
}

.bookbar-container{
  height: 50px;
  position: absolute;
  left: 0;
  right: -1px;
}

.bookbar.large .bookbar-container{
  height: 56px;
}

.bookbar .people{
  position: relative;
  display: inline-block;
  height: 50px;
  z-index: 9001;
  width: 164px;
  vertical-align: top;
}

.bookbar .people input{
  border: 0px solid;
  /* height: 24px; */
  line-height: 48px;
  /* margin-top: 5px; */
  margin-left: -1px;
  /* border-right: 1px solid rgba(184,184,184, 0.4); */
  z-index: 9001;
  padding-left: 30px;
  width: 164px;
  /* padding-top: 1px; */

}

.bookbar.large .people{
  position: relative;
  display: inline-block;
  height: 56px;
  z-index: 9001;
  vertical-align: top;
  font-size: 17px;
  width: 210px;
}

.bookbar.large .people input{
  border: 0px solid;
  line-height: 54px;
  font-size: 17px;
  padding-left: 36px;
  width: 210px;
  margin-left: -1px;

}

.bookbar .people .text{
  padding-left: 30px;
}


.bookbar.large .people .text{
  padding-left: 36px;
  font-size: 17px;

}

/* .bookbar.large .people .text{
  line-height: 56px;
  font-size: 17px;
} */

.bookbar .people .bookbar-text{
  padding-left: 30px;
}


.bookbar.large .people .bookbar-text{
  padding-left: 30px;
  font-size: 17px;
}

/* .bookbar.large .people .bookbar-text{
  line-height: 56px;
  font-size: 17px;
} */

.bookbar input:focus{
  border: 1px solid var(--input-highlight);
  box-shadow: 0 0;
  outline: none;
  /* height: 30px; */
  line-height: 48px;
  margin-top: -1px;
  padding-left: 29px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.bookbar.large input:focus{
  border: 1px solid var(--input-highlight);
  box-shadow: 0 0;
  outline: none;
  /* height: 30px; */
  line-height: 54px;
  /* margin-top: -1px;
  padding-left: 29px;
  padding-top: 0px;
  padding-bottom: 0px; */
}


.bookbar .zipcode input:focus{
  line-height: 28px;
}

.bookbar.large .people input:focus{
  line-height: 54px;
  margin-top: 0px;
  font-size: 17px;
}


.bookbar .SingleDatePicker_picker__openDown{
  top: 100%;
}

.bookbar.large .SingleDatePicker_picker__openDown{
  top: calc(100% + 2px);
}


.bookbar .duration, .bookbar .independent .duration{
  border: 0px solid;
  height: 50px;
  margin-top: -1px;
  margin-left: -1px;
  z-index: 9001;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 182px;
}

.bookbar .time-duration{
  top: 39px;
  width: 314px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 14px;
  padding-bottom: 18px;
}

.bookbar .time-dropdown .question{
  font-weight: 600;
  font-size: 15px;
  color: #575757;
  letter-spacing: -0.21px;
  line-height: 30px;
}

.bookbar .time-dropdown{
  height: 48px;
  width: 182px;
  margin-left: -1px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.new .bookbar .time-dropdown{
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 1px solid rgba(184,184,184, 0.4);
}

.eventDetailsModal .time-dropdown .question{
  font-weight: 600;
  font-size: 15px;
  color: #575757;
  letter-spacing: -0.21px;
  line-height: 30px;
}

.rc-time-picker-panel{
  z-index: 9000 !important;
  top: -3px !important;
}

.eventDetailsModal .rc-time-picker-panel{
  z-index: 9000 !important;
  top: 32px !important;
}

.bookbar .time{
 position: relative;
 width: 104px;
 height: 30px;
 vertical-align: middle;
 color: var(--primary-content-color);
}

.eventDetailsModal .time{
  position: relative;
  margin-top: -2px;
  width: 104px;
  height: 28px;
  vertical-align: middle;
  color: var(--primary-content-color);
 }

.bookbar .duration-text{
  position: absolute;
  right: 20px;
  top: 0;
}

.bookbar .to{
  padding-left: 4px;
  padding-right: 4px;
}

.bookbar .duration{
  display: inline-block;
  vertical-align: middle;
  width: 135px;
  height: 30px;
}

.bookbar .duration .duration-options.dropdown-menu{
  overflow: auto;
  max-height: 265px;
  top: 30px;
}


.eventDetailsModal .duration-text{
  position: absolute;
  right: 20px;
  top: 0;
}

.eventDetailsModal .to{
  padding-left: 4px;
  padding-right: 4px;
}

.eventDetailsModal .duration{
  display: inline-block;
  vertical-align: middle;
  width: 160px;
  height: 30px;
}

.eventDetailsModal .duration .duration-options.dropdown-menu{
  overflow: auto;
  max-height: 265px;
  top: 30px;
}

.bookbar .independent .duration .duration-options.dropdown-menu{
  overflow: auto;
  max-height: 265px;
  top: 50px;
}

.bookbar .list-member{
  position: relative;
}
.bookbar .list-member a{
  padding-left: 14px;
}

.eventDetailsModal .list-member a{
  padding-left: 14px;
  display: flex;
  justify-content: space-between;
}

.bookbar .list-member .number{
  position: absolute;
  right: 13px;
  top: 3px;
}

.bookbar .rc-time-picker-input{
  font-size: 14px;
  height: 30px;
  vertical-align: top;
}

.eventDetailsModal .rc-time-picker-input{
  font-size: 14px;
  height: 30px;
  vertical-align: top;
}

/* .eventDetailsModal .rc-time-picker-input .input[type="text"]{
  pointer-events: 
} */

.rc-time-picker-panel-inner{
  box-shadow: 0 10px 17px 0 rgba(0,0,0,0.30), 0 0 0 1px rgba(0,0,0,0.10) !important;
  margin-top: 34px !important;
  border-radius: 5px 5px 5px 5px !important;
  border: none !important;
  margin-left: 1px !important;
  overflow: hidden;
  color: var(--primary-content-color);
}

.eventDetailsModal .rc-time-picker-panel-inner{
  box-shadow: 0 10px 17px 0 rgba(0,0,0,0.30), 0 0 0 1px rgba(0,0,0,0.10) !important;
  margin-top: 0 !important;
  border-radius: 5px 5px 5px 5px !important;
  border: none !important;
  margin-left: 1px !important;
  overflow: hidden;
  color: var(--primary-content-color);
}

.rc-time-picker-panel-input-wrap{
  display: none;

}

.bookbar .time input:focus{
  padding-left: 7px;
  margin: 0;
  line-height: 28px;
}

.eventDetailsModal .time input:focus{
  padding-left: 7px;
  margin: 0;
  line-height: 28px;
}

li.rc-time-picker-panel-select-option-selected{
  color: white;
  background: var(--primary-action-color) !important;
}

li.rc-time-picker-panel-select-option-selected:hover{
  color: white;
  background: var(--primary-action-color-hover) !important;
}

.rc-time-picker-panel-select  li.rc-time-picker-panel-select-option-selected:hover{
  background: var(--primary-action-color-hover) !important;
  color: white;

}

.rc-time-picker-panel-select  li:hover{
  background: rgba(132, 132, 132, 0.08)  !important;
}

.rc-time-picker-panel-select {
  overflow-x: hidden;
}


.bookbar.large a.action-button{
  background: var(--primary-action-color);
  color: white;
  /* Action (opt.): */
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 15px;
  letter-spacing: -0.21px;
  flex: 1;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 56px;
}

.bookbar .duration .time-text{
  font-size: 14px;
  position: relative;
}

.bookbar .duration .durtext{
  font-size: 14px;
  position: relative;
}

.eventDetailsModal .duration .durtext{
  font-size: 14px;
  position: relative;
}

.bookbar .duration .dropdown-menu{
  top: 50px;
  left: 1px;
  margin-top: 0;
}

.bookbar .duration-options{
  overflow-anchor: none;
}

.bookbar .icon{
  vertical-align: middle;
  margin-bottom:0.25em;
  margin-right: 5px;
  display: inline-block;
  margin-left: 8px;
}

.bookbar.large .icon{
  vertical-align: middle;
  margin-bottom:0.25em;
  margin-right: 5px;
  display: inline-block;
  margin-left: 14px;
}

.bookbar.large .people .icon{
  vertical-align: middle;
  margin-bottom:0.25em;
  margin-right: 5px;
  display: inline-block;
  margin-left: 14px;
}

.bookbar .icon.date{
  position: absolute;
  left: 0;
  top: 50%;
  margin-left: 18px;
  transform: translateY(-50%);
}

.bookbar .input-missing{
  color: var(--missing-color);
}

.bookbar .input-missing .rc-time-picker-input::placeholder{
  color: var(--missing-color);
}

.bookbar .DateInput{
  background: transparent;
  width: inherit;
  height: 100%;
}

.bookbar.large .SingleDatePickerInput{
  width: 210px;
  cursor: pointer;
  font-size: 17px;
  background: transparent;
  height: 56px;
  display: flex;
  align-items: center;
}

.bookbar .DateInput_input{
  padding: 0;
  padding-left: 30px;
  /* padding-top: 1px; */
  margin-top: 5px;
  line-height: 30px;
  background: transparent;
  width:inherit;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  /* border-right: 1px solid rgba(184,184,184, 0.4); */
}

.new .bookbar .DateInput_input{
  padding: 0;
  padding-left: 42px;
  margin-top: -1px;
  /* margin-top: 0px; */
  line-height: 50px;
  background: transparent;
  width:inherit;
  border-top-left-radius:  9999px;
  border-bottom-left-radius: 9999px;
  /* border-right: 1px solid rgba(184,184,184, 0.4); */

}

.bookbar.large .DateInput_input{
  margin-top: 0px;
  line-height: 56px;
  font-size: 17px;
}

.bookbar input.DateInput_input:focus,  input.DateInput_input:active{
  line-height: 50px;
  margin-top: -1px;
  /* margin-left: -1px; */
  background-image: linear-gradient(-180deg, rgba(132,132,132,0.10) 0%, rgba(132,132,132,0.00) 100%);
  box-shadow: inset -1px 0 0 0 rgba(132,132,132,0.15), inset 1px 0 0 0 rgba(132,132,132,0.15);
  border: none;
}

.bookbar .input-missing input.DateInput_input::placeholder{
  color: var(--missing-color);
}

.bookbar.large .DateInput_input:focus, .bookbar.large .DateInput_input:active{
  line-height: 56px;
  font-size: 17px;
  margin-top: 0px;
}

.bookbar .SingleDatePickerInput{
  width: 184px;
  background: transparent;
  height: 50px;
  display: flex;
  align-items: center;
}

.bookbar .SingleDatePickerInput input{
  cursor: pointer;
}

.bookbar .divider{
  display: inline-block;
  border-right: 1px solid var(--primary-content-color);
  opacity: 0.4;
  height: 24px;
  vertical-align: middle;
  margin-bottom:0.25em;

}

.original .zipcode {
  position: relative;
  display: inline-block;
  width: 174px;
  line-height: normal;
  height: 50px;
  margin-left: -2px;
  cursor: pointer;
}

.new .zipcode {
  position: relative;
  display: inline-block;
  width: 186px;
  line-height: normal;
  height: 48px;
  margin-left: -1px;
  cursor: pointer;
  border-top-right-radius:  9999px;
  border-bottom-right-radius:  9999px;
  border: none;
}

.new .bookbar.large .zipcode {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.eventDetailsModal .zipcode {
  position: relative;
  display: inline-block;
  width: 100%;
  line-height: normal;
  cursor: pointer;
  position: relative;
  display: inline-block;
  line-height: normal;
  height: 48px;
  margin-left: -1px;
  cursor: pointer;
  border-top-right-radius:  9999px;
  border-bottom-right-radius:  9999px;
  border: none;
}




.bookbar.large .zipcode{
  height: 56px;
  width: 210px;
  vertical-align: top;
  font-size: 17px;
}

.eventDetailsModal .sort-box .dropdown-menu {
  top: 30px;
  min-width: 130px;
  max-height: 240px;
  overflow: scroll;
  scrollbar-width: none;
  left: 0;
  right: unset;
}

.zipcode label{
  font-weight: normal;
}

.zipcode:focus{
    outline: none;
}

.zipcode .question{
  font-family: "Open Sans"; font-weight: 600;
  font-size: 15px;
  color: var(--strong-content-color);
  letter-spacing: -0.21px;
  margin-top: 11px;
  margin-bottom: 11px;
}

.zipcode .text-field, .zipcode .text-field:focus {
  width: 100%;
  padding-left: 10px;
  margin-top: 0;
}


.zipcode .venue{
  opacity: 0.6;
  font-family: "Open Sans";
  font-size: 13px;
  color: var(--primary-content-color);
  padding-left: 25px;
}

.zipcode .ziptext{
  min-width: 100%;
  display: inline-block;
  line-height: 50px;
  height: 30px;
  margin-top: 5px;
  border-right: 1px solid rgba(184,184,184, 0.4);
}


.new .zipcode .ziptext{
  min-width: 100%;
  display: inline-block;
  line-height: 48px;
  height: 48px;
  margin-top: 0;
  border-right: none;
}

.bookbar.large .zipcode .ziptext{
  line-height: 56px;
  height: 56px;
  /* margin-top: 15px; */
  font-size: 17px;
}

.original .zipcode.selected{
  background-image: linear-gradient(-180deg, rgba(132,132,132,0.10) 0%, rgba(132,132,132,0.00) 100%);
  box-shadow: inset -1px 0 0 0 rgba(132,132,132,0.15), inset 1px 0 0 0 rgba(132,132,132,0.15);
  height: 50px;

}

.new .zipcode.selected{
  background-image: linear-gradient(-180deg, rgba(132,132,132,0.10) 0%, rgba(132,132,132,0.00) 100%);
  box-shadow: inset 1px 0 0 0 rgba(132,132,132,0.15);
  /* //inset 1px 0 0 0 rgba(132,132,132,0.15); */
  height: 48px;
}


.bookbar.large .zipcode.selected{
  height: 56px;
  margin-top: 0;
  font-size: 17px;
  background-image: linear-gradient(90deg, rgba(255,255,255,0.0) 80%, rgba(255,255,255,1.00) 100%), linear-gradient(-180deg, rgba(132,132,132,0.10) 0%, rgba(132,132,132,0.00) 100%) ;

}

/* .duration.open{
  background-image: linear-gradient(-180deg, rgba(132,132,132,0.10) 0%, rgba(132,132,132,0.00) 100%);
  box-shadow: inset -1px 0 0 0 rgba(132,132,132,0.15), inset 1px 0 0 0 rgba(132,132,132,0.15);
} */

.bookbar .time-dropdown.open{
  background-image: linear-gradient(-180deg, rgba(132,132,132,0.10) 0%, rgba(132,132,132,0.00) 100%);
  box-shadow: inset -1px 0 0 0 rgba(132,132,132,0.15), inset 1px 0 0 0 rgba(132,132,132,0.15);
}

.bookbar.large .time-duration{
  top: 56px;
  font-size: 17px;

}

.bookbar .zip-dropdown {
  display: none;
  position: absolute;
  left: 1px;
  top: 50px;
  min-width: 307px;
  z-index: 1;
  padding: 12px 16px;
  background: #FFFFFF;
  box-shadow: 0 10px 17px 0 rgba(0,0,0,0.30), 0 0 0 1px rgba(0,0,0,0.10);
  border-radius: 5px;
  font-size: 15px;
/*
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px; */

}

.eventDetailsModal .zip-dropdown {
  display: none;
  position: relative;
  min-width: 280px;
  width: 100%;
  z-index: 1;
  padding: 12px 16px;
  background: #FFFFFF;
  border-radius: 5px;
  font-size: 15px;
/*
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px; */

}

.eventDetailsModal .code{
  overflow: hidden;
  white-space: nowrap;
  word-wrap: break-word;
  min-width: 49px;
  display: inline;
}

.bookbar .ziptext .code{
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: break-word;
  max-width: 50px;
  min-width: 49px;
}

.new .bookbar .ziptext .code{
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: break-word;
  margin-bottom: 4px;
}

.bookbar.large .ziptext .code{
  max-width: 62px;
  min-width: 60px;
}

.zip-dropdown .not-available{
  font-weight: 600;
  font-size: 15px;
  color: var(--strong-content-color);
  margin-top: 14px;
}

.zip-dropdown label{
  line-height: 20px !important;
  margin-bottom: 10px;
}

.zip-dropdown input[type="radio"] + label:before{
  top: 1px;
}

.zip-dropdown input[type="radio"] + label:after{
  top: 7px;
}


.bookbar.large .zip-dropdown{
  top: 58px;
  left: 1px;
}

.bookbar.large .time-dropdown{
  width: 210px;
  height: 56px;
  vertical-align: top;
  margin-left: -1px;
  margin-top: 0px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

/* .new .bookbar.large .time-dropdown{
  width: 210px;
  height: 56px;
  vertical-align: top;
  margin-left: -1px;
  margin-top: 0px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
} */

.bookbar.large .time-text{
  font-size: 17px;
  position: relative;
  float: left;
}

.sign-in{
  display: inline-block;
  vertical-align: top;
  color: var(--primary-content-color);
  width: 106px;
  line-height: 64px;
  text-align: center;
}

.header.simple .sign-in{
  line-height: 44px;
}

.header.simple.white .sign-in{
  color: var(--primary-content-color);
  font-weight: 600;
}

.header.simple.white .sign-in:hover{
  color: var(--primary-content-color);
  font-weight: 700;
}

.account-wrapper{
  position: relative;


}

.account{
  color: var(--primary-content-color);
  text-align: center;
  display: inline-block;
  vertical-align: top;
  width: 109px;
  height: 64px;
  padding-top: 15px;
  cursor: pointer;
}

.header.simple .account{
  height: 44px;
  padding-top: 5px;

}

.header.simple .user{
  margin-top: 29px;
  display: inline-block;
}

.header.simple .user .cart-button{
  margin-left: 18px;
  margin-right: 20px;
  margin-top: 9px;
}

.header.simple .user .cart-button .cart-text{
  left: 2px;
}

.header.simple .account.open{
  border-radius: 3px 3px 0 0;

}

.header.simple .dropdown-menu{
  top: 44px;
}

.account .greeting{
  opacity: 0.5;
}
.account .text{
  font-family: "Open Sans"; font-weight: 600;
  line-height: 34px;
  /* margin-top: -3px; */
}

.more .text{
  line-height: 34px;
  padding-top: 4px;
}

.sign-in:hover{
  color: var(--primary-content-color);
  font-weight: bold;
  text-decoration: none;
}

.header .header-divider{
  display: inline-block;
  vertical-align: top;
  border-right: 1px solid black;
  opacity: 0.08;
  height: 24px;
  line-height: 64px;
}

.header .logo{
  float: left;
  height: 34px;
  margin-right: 16px;
  margin-left: 10px;
  margin-top: 19px;
}

.new .header .logo{
  float: left;
  margin-top: 4px;
}

.header .logo.vendor{

  margin-top: 36px;
}

.simple-logo{
  float: left;
  height: 61px;
  margin-top: 29px;

}

.nav-text{
  color: var(--primary-content-color);
  opacity: 0.8;
  font-family: "Open Sans";
  font-size: 15px;
  display: inline-block;
  vertical-align: top;
  padding-right: 0px;
  width: 140px;
  line-height: 64px;
  text-align: center;
}

a.nav-text{
  color: var(--primary-content-color);
  opacity: 0.8;
}

.header.simple .nav-text{
  line-height: 100px;
}


.header.simple.white .nav-text{
  color: white;
  opacity: 1;
  font-weight: 600;
}

.nav-text:hover{
  color: var(--primary-content-color);
  font-weight: bold;
  text-decoration: none;
  opacity: 1;

}

.header.simple.white .nav-text:hover{
  font-weight: bold;
  text-decoration: none;
  opacity: 1;
}

.nav-text:focus{
  color: var(--primary-content-color);
  font-weight: bold;
  text-decoration: none;
  opacity: 1;

}

.nav-text.selected{
  color: var(--primary-content-color);
  font-weight: bold;
  text-decoration: none;
  opacity: 1;
}

#more{
  background-color: white;
  padding: 0;
  font-size: 15px;
  height: 64px;
  margin-right: 15px;
  box-shadow: 0 0;
  border: none;
  color: var(--primary-content-color);
  display: inline-block;
  vertical-align: top;
  padding-right: 10px;
  margin-left: 20px;
}

.header.simple #more{
  height: 44px;
  margin-top: 27px;
}

.more{
  background-color: transparent;
  padding: 0;
  font-size: 15px;
  height: 64px;
  margin-right: 15px;
  box-shadow: 0 0;
  border: none;
  color: var(--primary-content-color);
  display: inline-block;
  vertical-align: top;
  padding-right: 10px;
  margin-left: 20px;
}

.header.simple .more{
  height: 44px;
  margin-top: 29px;
}


/*#more:hover{
  font-weight: bold;
  text-decoration: none;
}*/



.dropdown-menu{
  z-index: 9004;
  display: none;
  position: absolute;
  left: 0px;
  top: 68px;
  background: #FFFFFF;
  border: none;
  /* border-radius: 0; */
  /* Rectangle 10: */
  box-shadow: 0 10px 17px 0 rgba(0,0,0,0.30), 0 0 0 1px rgba(0,0,0,0.10);
  border-radius: 5px;

}

.dropdown-menu>li>a{
  /* Home Hovered Action: */
  font-family: "Open Sans";
  font-size: 15px;
  color: var(--primary-content-color);
  line-height: 28px;
}

.dropdown-menu>.active>a, .dropdown-menu>.active>a:focus, .dropdown-menu>.active>a:hover{
  background: var(--primary-action-color);
  font-weight: 600;
}

.dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover{
 background: rgba(132, 132, 132, 0.1);
 color: var(--strong-content-color);
}


.dropdown-menu.my-drop{
  display: block;
}

.account-wrapper .menu-item{
  padding: 10px;
  display: block;

}

#dropdown-custom-1{
  background-color: white;
  text-align: center;
  padding: 0;
  margin-left: 18px;
  box-shadow: 0 0;
  border: none;
  font-size: 15px;
  color: var(--primary-content-color);
}

#duration, #dropdown-custom-3{
  background-color: transparent;
  padding: 0;
  box-shadow: 0 0;
  line-height: 40px;
  width: 100%;
  text-align: left;
  border: none;
  font-size: 15px;
  color: var(--primary-content-color);
  cursor: pointer;
}

.header.simple.white #dropdown-custom-3{
  color: white;
  font-weight: 600;
}



.cart-button{
  position: relative;
  /* display: inline-block; */
  vertical-align: top;
  cursor: pointer;
  /* margin-top: 19px; */
  display: inline-flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 12px;
}

.my-caret{
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 4px;
  vertical-align: middle;
  border-top: 6px dashed var(--primary-content-color);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}


.my-small-caret{
  position: absolute;
  right: 7px;
  top: 48%;
  width: 0;
  vertical-align: middle;
  border-top: 4px dashed var(--primary-content-color);
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.my-small-caret.missing{
  border-top: 4px dashed var(--missing-color);
}

.header.simple.white .more .my-caret{
  border-top: 6px dashed white;

}

.my-caret.left{
  transform: rotate(90deg);
}

.cart-text{
  position: absolute;
  /*left: 2.5px;*/
  left: -4px;
  right: 0;
  top: 2px;
  bottom: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 12px;
  font-family: "Open Sans"; font-weight: 700;
  cursor: pointer;

}

.plain-header{
  position: absolute;
  top:0;
  right:0;
  left:0;
  height: 80px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  z-index:9001;
  text-align: center;
}

.plain-header .wrapper{
  width: 996px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  text-align: center;
}

.plain-header .large-logo{
  position: absolute;
  left: 0px;
  top: 14px;
}

.plain-header .text{
  opacity: 0.5;
  font-family: "Open Sans";
  line-height: 80px;
  /* font-size: 30px;
  color: var(--primary-content-color); */
  /* Review & Book: */
  font-size: 44px;
  color: #000000;
}

.header .created-wishlist{
  position: absolute;
  right: 82px;
  /* top: 55px; */
  top: -5px;
  width: 212px;
  z-index: 9999;
  padding: 12px 16px;
  background: #FFFFFF;
  box-shadow: 0 10px 17px 0 rgba(0,0,0,0.30), 0 0 0 1px rgba(0,0,0,0.10);
  border-radius: 5px;
}

/* .header .created-wishlist .arrow{
  position: absolute;
  right: 40px;
  top: -15px;
  color: white;
  transform: scaleX(2);
  /* height: 0px;
  width: 0px; */
  /* border-top: 0px solid transparent;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 9px solid white; */
  /* text-shadow: 0 10px 17px 0 rgba(0,0,0,0.30), 0 0 0 1px rgba(0,0,0,0.10);
  z-index: 0;
} */
/* .header .created-wishlist .arrow-2{
  position: absolute;
  right: 40px;
  top: -16px;
  color: rgba(0,0,0,0.10);
  transform: scaleX(2);
  font-size: 15px;
  /* height: 0px;
  width: 0px; */
  /* border-top: 0px solid transparent;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 9px solid white; */
  /* z-index: -1;
} */

.arrow:before {
  content: "";
  position: absolute;
  right: 112px;
  top: -30px;
  height: 0;
  width: 0;
  z-index: 9999;
  border: 13px solid transparent;
  border-bottom-color: white;
  margin-left: -13px;
}

.arrow:after {
  content: "";
  position: absolute;
  right: 116px;
  top: -14px;
  height: 18px;
  width: 18px;
  background-color: white;
  margin-left: -20px;
  margin-bottom: -20px;
  z-index: 9000;
  box-shadow: 0 10px 17px 0 rgba(0,0,0,0.30), 0 0 0 1px rgba(0,0,0,0.10);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.header .created-wishlist .title{
  font-weight:600;
  font-size: 15px;
  color: var(--strong-content-color);
}

.header .created-wishlist .text{
  font-size: 13px;
  color: var(--primary-content-color);
}

.header .created-wishlist .custom-button{
  width: 100%;
  display: block;
  float: none;
}


.header .vendor-header-text{
  font-size: 30px;
  color: var(--vendor-color);
  margin-top: 24px;
  float: left;
}
