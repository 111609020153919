.SingleDatePicker{
  vertical-align: top;
  height: 30px;
}

.eventDetailsModal .SingleDatePicker{
  vertical-align: top;
  height: auto;
  position: static;
}


.SingleDatePickerInput{
  padding: 0;
  margin: 0;
  border: none;
  color: var(--primary-content-color);
  width: 140px;
  height: 30px;
  /* margin-top: -1px; */
}

.eventDetailsModal .SingleDatePickerInput{
  padding: 0;
  margin: 0;
  border: none;
  color: var(--primary-content-color);
  width: 140px;
  height: 30px;
  margin-top: -1px;
  align-items: center;
  display: flex;
}


.DayPicker{
  /* Rectangle 10: */
  box-shadow: 0 10px 17px 0 rgba(0,0,0,0.30), 0 0 0 1px rgba(0,0,0,0.10);
  border-radius: 5px;
  /* box-shadow: 0 10px 17px 0 rgba(0,0,0,0.30);
  border-radius: 0 0 5px 5px; */
  height: fit-content;
  padding-bottom: 24px;
    /* margin-top: 1px; */
}

.eventDetailsModal .DayPicker{
  /* Rectangle 10: */
  /* box-shadow: 0 10px 17px 0 rgba(0,0,0,0.30), 0 0 0 1px rgba(0,0,0,0.10); */
  box-shadow: 0 0 0 0;
  border-radius: 20px;
  /* box-shadow: 0 10px 17px 0 rgba(0,0,0,0.30);
  border-radius: 0 0 5px 5px; */
  height: fit-content;
  padding-bottom: 24px;
  position: relative;
    /* margin-top: 1px; */
}


.SingleDatePicker .DayPicker{
  height: fit-content;
  padding-bottom: 24px;
}


.DayPicker::after{
  content: "Events must be booked at least 3 days in advance. Contact us if you need sooner.";
  font-family: "Open Sans";
  font-size: 13px;
  position: absolute;
  bottom: 10px;
  line-height: 15px;
  left: 23px;
  color: rgba(132,132,132,0.70);
}

.DayPicker li{
  margin-left: 0 !important;
}

.DateInput{
  padding: 0;
  margin: 0;
  border: none;
  height: 30px;
  vertical-align: top;

}

.eventDetailsModal .DateInput{
  padding: 0;
  margin: 0;
  border: none;
  height: 30px;
}

.DateInput_input{
  padding: 1px;
  margin: 0;
  color: var(--primary-content-color);
  font-weight: normal;
  font-size: 16px;
  border: none;
  width: 140px;
  line-height: 30px;
  background-color: transparent;
}

.eventDetailsModal .DateInput_input{
  padding: 1px;
  margin: 0;
  color: var(--primary-content-color);
  font-weight: normal;
  font-size: 16px;
  border: none;
  width: 140px;
}

.DateInput_input__focused{
  border-bottom: 0;
}

.DayPicker_transitionContainer{
  width: 316px !important;
}

.eventDetailsModal .DayPicker_transitionContainer{
  width: auto !important;
}


.DateInput__openDown:after{
  top: 43px;
}

.DateInput__openDown:before {
  top: 42px;
}

.SingleDatePicker_picker__openDown{
  top: 42px;
  border-radius: 5px;
}

.reschedule-modal .SingleDatePicker_picker__openDown{
  top: calc(100% + 2px);
  left: -12px !important;
  border-radius: 5px;
}

.eventDetailsModal .SingleDatePicker_picker__openDown{
  top: 40px;
  border-radius: 20px;
  /* left: -42px !important; */
  /* margin-left: -13px; */
  position: absolute;
  display: flex;
  justify-content: center;
  right: 0;
  left: 0;
  /* transform: translateX(-50%); */

}
/*
.SingleDatePicker_picker__openDown .DayPicker{
  left: -20px;
} */

.CalendarDay_container:hover{
  background: rgba(132, 132, 132, 0.08);
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background: var(--primary-action-color);
  border: 1px solid var(--primary-action-color);

}

.CalendarDay__selected .CalendarDay_button{
  color: white;
  font-size: 15px;
  font-weight: 600;/* 4 11 18 25: */

}


.DateInput__openDown:after{
  display: none;
}

.DateInput__openDown:before{
  display: none;
}

.CalendarDay__selected.CalendarDay__blocked_calendar{
  background:
        linear-gradient(to top left,
            rgba(251, 178, 0, 1) 0%,
            rgba(251, 178, 0, 1) calc(50% - 0.8px),
            rgba(255, 255, 255, 1) 50%,
            rgba(251, 178, 0, 1) calc(50% + 0.8px),
            rgba(251, 178, 0, 1) 100%),
        linear-gradient(to top right,
            rgba(132, 132, 132, 0.0) 0%,
            rgba(132, 132, 132, 0.0) calc(50% - 0.8px),
            rgba(132, 132, 132, 0.0) 50%,
            rgba(132, 132, 132, 0.00) calc(50% + 0.8px),
            rgba(132, 132, 132, 0.00) 100%);
}

.CalendarDay__selected.CalendarDay__blocked_calendar:hover{
  background:
        linear-gradient(to top left,
            rgba(251, 178, 0, 1) 0%,
            rgba(251, 178, 0, 1) calc(50% - 0.8px),
            rgba(255, 255, 255, 1) 50%,
            rgba(251, 178, 0, 1) calc(50% + 0.8px),
            rgba(251, 178, 0, 1) 100%),
        linear-gradient(to top right,
            rgba(132, 132, 132, 0.0) 0%,
            rgba(132, 132, 132, 0.0) calc(50% - 0.8px),
            rgba(132, 132, 132, 0.0) 50%,
            rgba(132, 132, 132, 0.00) calc(50% + 0.8px),
            rgba(132, 132, 132, 0.00) 100%);
}


.CalendarDay__selected.CalendarDay__blocked_calendar .CalendarDay_button {
  color: white;
}


.CalendarDay__blocked_calendar, .CalendarDay__blocked_calendar:hover {
  background:
        linear-gradient(to top left,
            rgba(132, 132, 132, 0.08) 0%,
            rgba(132, 132, 132, 0.08) calc(50% - 0.8px),
            rgba(132, 132, 132, .2) 50%,
            rgba(132, 132, 132, 0.08) calc(50% + 0.8px),
            rgba(132, 132, 132, 0.08) 100%),
        linear-gradient(to top right,
            rgba(132, 132, 132, 0.0) 0%,
            rgba(132, 132, 132, 0.0) calc(50% - 0.8px),
            rgba(132, 132, 132, 0.0) 50%,
            rgba(132, 132, 132, 0.00) calc(50% + 0.8px),
            rgba(132, 132, 132, 0.00) 100%);

   border: 1px solid #e4e7e7;
}


.CalendarDay__blocked_calendar .CalendarDay_button{
  font-size: 15px;
  color: rgba(132, 132, 132, .2);
  font-weight: normal;
}

.CalendarDay__blocked_out_of_range .CalendarDay_button{
  font-size: 15px;
  color: rgba(132, 132, 132, .2);
  font-weight: normal;
}

.CalendarDay__blocked_out_of_range{
  background:
        linear-gradient(to top left,
            rgba(132, 132, 132, 0.08) 0%,
            rgba(132, 132, 132, 0.08) calc(50% - 0.8px),
            rgba(132, 132, 132, .2) 50%,
            rgba(132, 132, 132, 0.08) calc(50% + 0.8px),
            rgba(132, 132, 132, 0.08) 100%),
        linear-gradient(to top right,
            rgba(132, 132, 132, 0.0) 0%,
            rgba(132, 132, 132, 0.0) calc(50% - 0.8px),
            rgba(132, 132, 132, 0.0) 50%,
            rgba(132, 132, 132, 0.00) calc(50% + 0.8px),
            rgba(132, 132, 132, 0.00) 100%);

   border: 1px solid #e4e7e7;
 }

.DayPicker_weekHeader{
  opacity: 0.5;
  font-family: "Open Sans";
  color: var(--primary-content-color);
}

.CalendarMonth_caption{
  font-family: "Open Sans";
  font-size: 15px;
  color: var(--primary-content-color);
  padding-bottom: 44px;
}

.eventDetailsModal .CalendarMonth_caption{
  font-family: "Open Sans";
  font-size: 15px;
  color: var(--primary-content-color);
  padding-bottom: 44px;
}

.CalendarMonth_caption strong{
  font-weight: 600;
}

/* .eventDetailsModal .CalendarMonth {
  padding-left: 0;
  padding-right: 0;
} */


.CalendarDay_button {
  font-family: "Open Sans";
  font-size: 15px;
  color: var(--primary-content-color);
}
