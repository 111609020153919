.package-booked{
  margin-bottom: 108px;
}

.package-booked .footer.simple{
  padding-left: 0;
  width: 800px;
}

.block-header{
  background: #FFFFFF;
  box-shadow: 0 1px 10px 0 rgba(0,0,0,0.03);
  border-radius: 5px;
  width: 800px;
  margin-top: 50px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.block-header .title b{
  font-family: "Open Sans"; font-weight: 600;
  color: var(--strong-content-color);
}
.block-header .title{
  font-family: "Open Sans";
  font-size: 28px;
  color:var(--primary-content-color);
  padding-left: 20px;
  padding-right: 20px;
}

.block-header .title .emphasis{
  font-family: "Open Sans"; font-weight: 600;
  color: var(--strong-content-color);
}

.block-header .subtitle{
  opacity: 0.8;
  font-family: "Open Sans";
  font-size: 17px;
  width: 597px;
  display: inline-block;
  color: var(--primary-content-color);
  margin-top: 8px;
  margin-bottom: 8px;

}

.block-header .icon{
  margin-top: 27px;
  margin-bottom: 15px;
}

.addons{
  width: 800px;
  margin-top: 44px;
  margin-left: auto;
  margin-right: auto;
}

.addons .powerup_list{
  margin-left: -50px;
  padding-left: 0;
}

.addons .title{
  font-family: "Open Sans"; font-weight: 600;
  font-size: 19px;
  color: var(--primary-content-color);
}

.addons .subtitle{
  opacity: 0.5;
  font-family: "Open Sans";
  font-size: 15px;
  color: var(--primary-content-color);
  letter-spacing: -0.21px;
}
