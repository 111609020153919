.static-page{
  margin-top: 115px;
  /* padding-bottom: 450px; */
  width: 1028px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.static-page.vendor-dash{
  margin-top: 100px;
}

.static-page.wide{
  width: 100%;

}

.contact .info{
  display: inline-block;
  vertical-align: top;
  width: 310px;
  color: var(--primary-content-color);
}

.contact .info .small-text {
  font-size: 13px;
}

.contact .map{
  display: inline-block;
  vertical-align: top;
  width: 570px;
  margin-right: 58px;
  margin-bottom: 108px;
}

.service{
}

.service .title{
  /* Now serving the Bay: */
  font-weight: 600;
  font-size: 28px;
  color: var(--strong-content-color);
  letter-spacing: -0.36px;
}

.service .text{
  font-size: 15px;
  margin-top: 15px;
}

.service .text.section{
  /* border-bottom: 1px solid rgba(132, 132, 132, 0.2); */
  padding-bottom: 56px;
  /* margin-bottom: 32px; */
}

.service .text .text-field{
  width: 212px;
  margin-right: 8px;
}

.service .text .text-field.zip{
  width: 68px;
  padding-left: 8px;
}



.service .text b{
  font-weight: 600;
  color: var(--strong-content-color)
}

.service .text p{
  margin-bottom: 20px;
}

.service .service-map{
  display: inline-block;
  vertical-align: top;
  height: 538px;
  margin-right: 40px;
}

.service .pin{
  position: relative;
  width: 84px;
  display: inline-block;
  height: 66px;
}

.service .pin-icon{
  position: absolute;
  left: 25%;
  top: -2px;
  z-index: 10;
  height: 64px;
  /* width: 32px; */
}

.service .info{
  display: inline-block;
  vertical-align: middle;
  width: 532px;
  padding-right: 52px;
  border-right: 1px solid rgba(132, 132, 132, 0.2);
  color: var(--primary-content-color);
}

.service .sign-up{
  display: inline-block;
  vertical-align: middle;
  width: 368px;
  margin-left: 24px;
  color: var(--primary-content-color);

}

.service .mini-map{
  width: 84px;
  height: 36px;
  position: absolute;
  bottom: 0;
}
.service .card{
  margin-bottom: 100px;
}

.contact .info .section{
  margin-bottom: 20px;
}

.contact .info .info-header{
  font-size: 15px;
  font-weight: 600;
  color: var(--strong-content-color);
}

.contact .info .number{
  font-size: 20px;
}

.contact .info .number a{
    color: #9DDD22;
}

.contact .info .number a:hover{
    color: var(--primary-content-color);
}

.contact .title-wrapper{
  margin-bottom: 57px;
}

.static-page ul.description {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.static-page-subheader{
  opacity: 0.5;
  font-size: 15px;
  color: var(--primary-content-color);
  letter-spacing: -0.21px;
  margin-bottom: 50px;
}

.static-page.wide .static-page-subheader{
  width: 996px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}


.static-page .nav-links {
  font-size: 13px;
  width: 996px;  
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.static-page .nav-links img{
  margin-bottom: 2px;
  margin-left: 7px;
  margin-right: 7px;
}


/* .static-page .description ul > li {
  position: relative;
  padding-left: 10px;
}

.static-page .description ul > li:before {
  content: "•";
  position: absolute;
  left: 0;
} */
