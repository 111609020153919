

.customize-modal{
  /* Rectangle 10: */
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 22px 49px 0 rgba(0,0,0,0.19);
  overflow: hidden;
  /* margin-top: 150px; */
  padding: 20px;
  /* position: relative; */
  width: 550px;
  max-width: 95%;
  max-height: 95%;
  overflow-y: scroll;
  z-index: 2041;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  margin: 0;
}

.generic-modal{
  /* Rectangle 10: */
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 22px 49px 0 rgba(0,0,0,0.19);
  overflow: hidden;
  margin-top: 150px;
  padding: 20px;
  position: relative;
  width: 550px;
  max-width: 95%;
  z-index: 2041;
}

.customize-modal .options {
  margin-top: 11px;
  width: 510px;
  max-width: 100%;
  margin-left: auto;
  margin-right:auto;
}

.customize-modal .customize-powerups{
  display: block;
  width: 100%;
}


/* .customize-modal .options .opt{
  width: 250px;
  display: inline-block;
  overflow: hidden;
  position: relative;
  margin-bottom: 4px;
}

.customize-modal .options label{
  white-space: nowrap;
  width: 198px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
} */

.customize-modal .options .opt{
  width: 250px;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin-bottom: 6px;

}

.customize-modal .options label{
  font-family: "Open Sans";
  margin: 0;
  font-size: 15px;
  font-weight: normal;
  min-height: 30px;
  line-height: 30px;
  color: var(--primary-content-color);
  letter-spacing: -0.21px;
  white-space: nowrap;
  max-width: 198px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
}


.customize-modal .options .opt .text-field{
  position: absolute;
  left: 35;
  top: 0px;
  height: 26px;
  width: 200px;
  margin: 0;
}


.customize-backdrop{
  z-index: 2040;
}

.customize-backdrop.opaque{
  opacity: 0.9 !important;
}

.customize-modal .title{
  opacity: 0.5;
  font-family: "Open Sans";
  font-size: 28px;
  color: #000000;
}

.customize-modal .subtitle{
  opacity: 0.5;
  font-family: "Open Sans";
  font-size: 17px;
  color: #000000;
}

.customize-modal .text{
  font-family: "Open Sans";
  font-size: 17px;
  color: var(--primary-content-color);
  margin-top: 20px;
  margin-bottom: 10px;
}
.customize-modal .text b{
  font-weight: 600;
}

.customize-modal .text ol{
  padding-left: 40px;
}

.customize-modal.terms .text li{
  padding-bottom: 10px;
}

.customize-modal.terms .text{
  font-size: 14px;
}

.customize-modal.terms .text p{
  padding-bottom: 10px;
}

.customize-modal.terms {
  width: 996px;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 50px;
}

.customize-modal label{
  font-weight: normal;
  color: var(--primary-content-color);
}

.customize-modal .text-field{
  margin-left: 25px;
  margin-right: 4px;
  display: inline-block;
}

.customize-modal  .bookbar .text-field{
  margin-left: 0px;
  margin-right: 0px;
  display: inline-block;
}

.customize-modal .bookbar{
  margin-top: 24px;
  margin-bottom: 35px;

}

.text-field.person{
  padding-left: 25px;
}

.text-field.hour{
  padding-left: 25px;
}

.customize-modal .create-wishlist{
  display: block;
  width: 100%;
  margin-left: 0;
  margin-top: 10px;
  margin-bottom: 16px;
}

.customize-modal.booking-modal{
  overflow: visible;
  width: 740px;
}


.generic-modal.booking-modal{
  overflow: visible;
  width: 740px;
}
