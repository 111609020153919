.booked{
  margin-top: 50px;
  margin-bottom: 100px;

  margin-right: auto;
  margin-left: auto;
  width: 996px;
  height: 527px;
  vertical-align: top;
}


.booked .content{
  width: 417px;
  margin-left: 30px;
  display: inline-block;
}

.booked .content .title{
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 28px;
  color: var(--strong-content-color);
  letter-spacing: -0.36px;
  margin-bottom: 15px;
  margin-top: 37px;
}


.booked .text{
  font-family: "Open Sans";
  font-size: 15px;
  color: var(--primary-content-color);
}

.booked .text p{
  margin-bottom: 15px;
}

.booked .booked-image{
  margin-left: 40px;
  margin-top: 37px;
}
/*
.booked .image {
  height: 295px;
  width: 411px;
  margin: 8px;
  border: none;
  outline: none;
}

.booked .image:hover {
  border: none;
  outline: none;
} */

.booked .visit-dash{
  color: var(--input-highlight);
}
