
.title-wrapper{
  display: inline-block;
  margin-bottom: 10px;

}

.title-wrapper .title{
  font-size: 28px;
  color: black;
  opacity: 0.5;
  display: block;
  text-align: left;
  line-height: normal;
}

.title-wrapper .subtitle{
  font-size: 15px;
  display: block;
  text-align: left;
  color: var(--primary-content-color);
  opacity: 0.5;
  width: 690px;
  max-width: 100%;
}
