.cart{
  width: 320px;
  /*height: 826px;*/
  /*min-height: 100%;*/
  position: absolute;
  top: 74px;
  height: calc(100vh - 74px);
  /* right: -320px; */
  right: 0;
  background-color: white;
  z-index: 50;
  border-top: 1px solid rgba(0, 0, 0, 0.08);/* Rectangle 9: */
  box-shadow: 0 1px 10px 0 rgba(0,0,0,0.03);
  border-right: 0;
  transition: .2s ease-out;
  overflow-y: hidden;

}

.cart-title{
  opacity: 0.6;
  font-size: 20px;
  color: var(--primary-content-color);
  margin-left: 20px;
  margin-top: 15px;
}

.powerup{
  height: 40px;
  width: 100%;
  margin-top: 8px;
  /*padding: 10px;*/
  display: flex;
}


.powerup .powerup-image-container{
  height: 40px;
  width: 40px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, .03);
  border-radius: 4px;
  display: inline-block;
  cursor: pointer;
}

.powerup .powerup-image-container .powerup-image{
  display: inline-block;
  height: 40px;
}
.powerup .powerup-details{
  width: 100%;
  line-height: 40px;
  height: 40px;
  display: block;
}

.powerup .powerup-details.qty{
  line-height: normal;
  height: 20px;
}

.powerup .powerup-details.unavailable{
  line-height: normal;
  height: 20px;
}


.powerup .quantity{
  width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: none;
}

.powerup .quantity.unavailable{
  display: block;
  font-size: 13px !important;
  color: var(--missing-color) !important;
  opacity: 1 !important;
}

.powerup:hover .quantity.unavailable{
  display: none;
}

.powerup .quantity.qty{
  opacity: 0.6;
  font-family: "Open Sans";
  font-size: 13px;
  color: var(--primary-content-color);
  display: block;
}

.powerup:hover .quantity.qty{
  display: none;
}

.powerup:hover .quantity.qty.included{
  display: block;
}

.powerup .powerup-wrapper{
  height: 40px;
  display: inline-block;
  flex: 1;
  margin-left: 10px;
}


.powerup .powerup-name{
  display: inline-block;
  font-size: 13px;
  overflow: hidden;
  font-family: "Open Sans"; font-weight: 600;
  color: var(--primary-content-color);
  cursor: pointer;
  max-width: 140px;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.cart-contents .hovered{
  display: none;
  color: #ccc;
}

.powerup:hover .hovered{
  display: block;
}

.cart-contents .change{
  font-family: "Open Sans"; font-weight: 600;
  font-size: 13px;
  color: var(--primary-action-color);
  cursor: pointer;
}

.cart-contents .remove{
  font-family: "Open Sans"; font-weight: 600;
  font-size: 13px;
  color: #FF5200;
  cursor: pointer;
}

.cart-contents .cancellation-fee{
  width: 300px;
  margin-left: 42px;
  display: inline-block;
  vertical-align: middle;
  font-size: 13px;
  color: #FF5200
}

.cart .no-host{
  margin-left: 20px;
  padding-bottom: 4px;
  color: var(--primary-content-color);
  font-size: 13px;
  position: absolute;
  top: 70px;
  background: white;
  z-index: 1;
}

.powerup .powerup-price{
  float: right;
  font-size: 13px;
  overflow: hidden;
  opacity: 0.6;
  color: var(--primary-content-color);
}
/*
.powerup:hover {
  background-color: rgba(0,0,0,.03);
  border-radius: 2px;
} */

.powerup:hover .powerup-price{
  display: none;
}

.powerup:hover .powerup-price.included{
  display: inline-block;
}

.powerup .powerup-price-per{
  display: none;
}
.powerup:hover .powerup-price-per{
  display: block;
  float: right;
  font-size: 13px;
  overflow: hidden;
  opacity: 0.6;
  color: var(--primary-content-color);
}

/*.powerup:hover{
  display: block;
  position: relative;
}*/

.powerup:hover .powerup-details{
  line-height: normal;
  height: 20px;
}

.powerup:hover .powerup-details.included{
  line-height: 40px;
  height: 40px;
}

.powerup:hover .powerup-details.qty.included{
  line-height: normal;
  height: 20px;
}

.package{
  position: relative;
  border-radius: 5px;
  border: 1px solid rgba(0,0,0,0.17);
  height: 217px;
  width: 279px;
  overflow: hidden;
}

.package .image {
  height: 140px;
  /* cursor: pointer; */
  border-bottom: 1px solid rgba(0,0,0, 0.1);
  display: inline-block;
  margin-left: -45px;
  margin-top: 0;
  cursor: pointer;

}


.package .name{
  margin-left: 11px;
  margin-top: 5px;
  font-family: "Open Sans"; font-weight: 700;
  font-size: 15px;
  color: var(--strong-content-color);
  letter-spacing: -0.21px;
  display: inline-block;
  cursor: pointer;
}

.package:hover .name{
  width: 170px;
  height: 21px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.package .price{
  margin-top: 5px;
  margin-right: 11px;
  opacity: 0.8;
  font-size: 13px;
  color: var(--strong-content-color);
  letter-spacing: -0.19px;
  float: right;
}

.package:hover .price{
  display: none;
}

.package .price-per{
  display: none;
}

.package:hover .price-per{
  margin-top: 5px;
  margin-right: 11px;
  opacity: 0.8;
  font-size: 13px;
  color: var(--strong-content-color);
  letter-spacing: -0.19px;
  float: right;
  display: inline-block;
}

.package .includes{
  opacity: 0.6;
  font-size: 13px;
  margin-left: 10px;
  color: var(--primary-content-color);
  letter-spacing: -0.19px;
}

.package .includes.unavailable{
  opacity: 1;
  font-size: 13px;
  margin-left: 10px;
  color: var(--missing-color);
  letter-spacing: -0.19px;
}

.package:hover .includes{
  display: none;
}

.package:hover .hovered{
  display: block;
  margin-left: 10px;
  position: absolute;
  left: 0px;
  bottom: 11px;
}


.item-list{
  overflow: auto;
  padding: 20px;
  /* padding-bottom: 129px; */
  margin-bottom: 0;
  padding-top: 0;
  position: absolute;
  top:75px;
  width: 100%;
  bottom:129px;
}

.empty-cart{
  height: 100%;
  text-align: center;
  vertical-align: middle;
  display: table;
  padding: 20px;
  font-family: "Open Sans";
  font-weight: 300;
  min-height: 200px;
}

.empty-cart .empty{
  color: var(--primary-content-color);
  font-size: 24px;
  margin-top: -120px;
}

.empty-cart .add-items{
  color: var(--primary-content-color);
  font-size: 18px;
}

.cart .checkout{
  border-top: 1px solid rgba(0,0,0, 0.04);
  padding: 20px;
  padding-top: 11px;
  position: absolute;
  bottom: 0;
  background: white;
  width: 100%;
  /* height: 134px; */
  /*padding-bottom: 17px;*/
}

.cart.new .checkout{
  border-top: 1px solid rgba(0,0,0, 0.04);
  padding: 20px;
  padding-top: 11px;
  position: relative;
  flex:none;
  display: flex;
  background: white;
  width: 100%;
  margin-top: auto;
  flex-direction: column;
  /* height: 134px; */
  /*padding-bottom: 17px;*/
}

.cart .checkout .total-price{
  float: right;
  color: var(--primary-content-color);
  font-family: "Open Sans"; font-weight: 700;
}

.cart .checkout .approximate{
  color: var(--primary-content-color);
  font-size: 15px;
  display: inline-block;
}

.cart .checkout .text{
  color: var(--primary-content-color);
  font-size: 12px;
  margin-top: 4px;
}
.cart .checkout .large-text{
  color: rgba(132, 132, 132, 0.8);
  font-family: "Open Sans";
  font-size: 15px;
  margin-top: 4px;
}
/* Enter in more detail: */
.cart .checkout .large-text b{
  /* Unfortunately we’re: */
  font-weight: 600;
  opacity: 1;
  font-size: 15px;
  color: var(--strong-content-color);
}

.cart .checkout.unavailable .large-text{
  opacity: 0.8;
  font-weight: 600;
  font-size: 15px;
  color: var(--strong-content-color);
  width: 230px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 12px;
}

.cart .checkout.unavailable .small-text{
  /* Change or Remove una: */
  opacity: 0.8;
  font-size: 13px;
  color: var(--primary-content-color);
  margin-top: 12px;
  padding-bottom: 20px;
}

.cart .checkout.unavailable .icon{
  height: 33px;
}

.cart .params{
  margin-right: 20px;
  margin-left: 20px;
  font-size: 13px;
  color: var(--primary-content-color);
}

.cart .params .emphasis{
  font-family: "Open Sans"; font-weight: 700;
  color: var(--primary-content-color);
}
