.landing{
  margin-bottom: 450px;
}

.landing .hero {
  /* Mask: */
  height: 490px;
  width: 100%;
  background: linear-gradient(to right, var(--highlight-color), var(--highlight-accent));
  position: relative;
}

.hero .image{
  height: 490px;
  margin-left: 650px;
}

/* .hero .newimage {
  /* background: radial-gradient(circle, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  background-image: url("../img/HomeHeroBarHappyPeople@2x.jpg");
  height: 100%;
  width: fit-content; 
  }*/

.hero .newimage img {
  -webkit-mask-image: linear-gradient(
    to right,
    transparent,
    black 10%,
    black 90%,
    transparent 100%
  );
  mask-image: linear-gradient(
    to right,
    transparent,
    black 10%,
    black 90%,
    transparent 100%
  );
}

.hero .image-wrapper{
  left: 0;
  right: 0;
  margin: auto;
  width: 1400px;
  overflow: hidden;
  position: absolute;
  top:0;
}

.hero .overlay-wrapper{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 1400px;

}


.hero .overlay{
  width: 100%;
  background-image: linear-gradient(103deg, #9EDD22 8%, #ADDD21 35%, #B1DD21 49%, rgba(180,221,32,0.90) 60%, rgba(190,222,31,0.92) 83%, #C2DE1F 91%, #D0DE1E 100%);
}

.hero .confetti{
  background-image: url("../img/HomeHeroBarConfettiOverlay@2x.png");
  background-repeat: repeat;
  background-size: 1146px 400px;
  height: 400px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.hero .hero-content{
  /*margin-right: 218px; */
  width: 996px;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 163px;
  position: absolute;
}

.hero .hero-content .title{
  /* Instantly book your: */
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 42px;
  color: #FFFFFF;
  letter-spacing: -1.12px;
}

.hero .hero-content .subtext{
  font-family: "Open Sans";
  font-size: 20px;
  color: #FFFFFF;
  letter-spacing: 0;
  margin-top: 5px;
  width: 639px;
  margin-bottom: 41px;
}

.landing .users{
  height: 117px;
  width: 996px;
  margin-left: auto;
  margin-right: auto;
}

.landing .users .hero-company-logos{
  margin-top: 40px;
  width: 996px;
  cursor: pointer;
}

.landing .big-company-logos{
  width: 996px;
  margin-top: 35px;
}

.landing .custom-button {
  float: none;
  line-height: 56px;
  display: inline-block;
  width: 159px;
  font-size: 17px;
  margin-top: 0;

}

.landing.custom-button {
  float: none;
  line-height: 56px;
  display: block;
  width: 159px;
  font-size: 17px;
  margin-top: 0;
}


.landing .bookbar .custom-button{
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.bookbar.large .custom-button{
  border-bottom-left-radius: 9999px;
  border-top-left-radius: 9999px;
  border-bottom-right-radius: 9999px;
  border-top-right-radius: 9999px;
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border: 3px solid white;


}

.landing .bookbar{
  float: none;
}

.landing .users .company-logo{
  display: inline-block;
  margin-right: 60px;
  margin-top: 45px;
  vertical-align: top;
}

.section-wrapper{
  background: linear-gradient(rgba(0, 0, 0, 0.02), transparent 60px) no-repeat;
}

.how-to-section{
  margin-left: auto;
  margin-right: auto;
  padding-top: 63px;
  width: 996px;
  padding-bottom: 100px;
}

.how-to{
  /* margin-left: 218px; */
  width: 680px;
  display: inline-block;
}

.insta-container{
  height: 553px;
  display: inline-block;
  vertical-align: top;
  width: 314px;

}

.insta-container .insta{
  margin: 12px;
  margin-top: 20px;
}

.insta-container .username{
  color: var(--input-highlight);
  float: right;
  display: inline-block;
  font-size: 13px;
  margin-top: 20px;
}

.insta-container .logo-circle{
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border: 1px solid rgba(132, 132, 132, 0.2);
  text-align: center;
  float: right;
  display: inline-block;
  margin: 12px;
}

.insta-container .small-logo{
  height: 20px;
  margin-top: 8px;

}

.insta_container .name:hover{

}

.my-instagram-widget{
  /* height: 477px; */
  vertical-align: top;
  margin: 6px;
}



.how-to .how{
  width: 315px;
  height: 250px;
  display: inline-block;
  vertical-align: top;
  margin-right: 25px;
}

.how .title{
  margin-top: 14px;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 19px;
  color: var(--strong-content-color);
  letter-spacing: -0.27px;
  margin-bottom: 9px;
  line-height: normal;
}

.how .text{
  font-family: "Open Sans";
  font-size: 13px;
  color: var(--primary-content-color);
  letter-spacing: -0.19px;
  width: 267px;
}

.section-wrapper .packages {
  padding-top: 68px;
  width: 100%;
  padding-bottom: 100px;
}

.packages .title-wrapper{
  width: 996px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.carousel.cmask{
  /* min-width: 996px;
  width: 1300px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  height: 382px;
  position: relative; */
  mask-image: linear-gradient(-90deg, transparent 0, #D8D8D8 20%, #D8D8D8 80%, transparent 100%);

}/* Mask: */
  /* mask-image: linear-gradient(to left,
     rgba(252,252,252, 1) 85%,
     transparent 100%
  ), linear-gradient(to right,
     rgba(252,252,252, 1) 85%,
     rgba(252,252,252, 0) 100%
  ); */
  /* box-shadow: -10px 0 20px 0 rgba(255,255,255,1) inset, 10px 0 20px 0 rgba(255,255,255,1) inset; */


/* .packages .carousel:after {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  content: "";
  pointer-events: none;
} */
  /* background: linear-gradient(to left,
     rgba(252,252,252, 0) 85%,
     rgba(252,252,252, 1) 100%
  ), linear-gradient(to right,
     rgba(252,252,252, 0) 85%,
     rgba(252,252,252, 1) 100%
  ), linear-gradient(to bottom,
     rgba(252,252,252, 0) 100%,
     rgba(252,252,252, 1) 85%
     ) ; */
 

/* .packages .carousel .overlay{
  background-image: linear-gradient(-90deg, rgba(238,238,238,1) 4%, #D8D8D8 20%, #D8D8D8 80%, rgba(238,238,238,1) 96%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
} */

.packages .carousel ul{
  width: 1800px;
  margin-left: -260px;
  position: absolute;
  top: 0;
  margin-top: 30px;
}

.packages .carousel li{
  display: inline-block;
  margin-left: 25px;
  vertical-align: top;
  transition: .1s ease-out;

}

/* .carousel .jaunty {
  transform: rotate(2deg)
}

.carousel .unjaunty{
  transform: rotate(-2deg);
  padding-top:15px;
} */

/* .packages .carousel li.jaunty:hover, .packages .carousel li.unjaunty:hover{
  transform: rotate(0deg);
} */



.section-wrapper .packages .custom-button{
  margin-left: auto;
  margin-right: auto;
  display: block;

}

.builder{
  margin-left: auto;
  margin-right: auto;
  width: 996px;
  padding-top: 68px;
  padding-bottom: 100px;
}

.builder .title-wrapper, .something-more .title-wrapper, .logo-list .title-wrapper{
  display: block;
  margin-bottom: 28px;
}

.builder .description{
  width: 380px;
  display: inline-block;
  vertical-align: top;
}

.builder .pic{
  height: 320px;
  display: inline-block;
  margin-left: 34px;
}

.builder .subheader, .something-more .subheader{
  /* Our unique interface: */
  font-weight: 600;
  font-size: 15px;
  color: var(--strong-content-color);
  letter-spacing: -0.21px;
}

.builder .text, .something-more .text{
  margin-bottom: 30px;
  font-size: 13px;
  color: var(--primary-content-color);
  letter-spacing: -0.19px;
}

.builder .custom-button{
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 55px;
  width: 290px;
}

.logo-list{
  margin-left: auto;
  margin-right: auto;
  width: 996px;
  padding-top: 68px;
  padding-bottom: 100px;
}

.something-more{
  margin-left: auto;
  margin-right: auto;
  width: 996px;
  padding-top: 68px;
  padding-bottom: 63px;
}

.something-more .content{
  width: 512px;
  display: inline-block;
  margin-right: 57px;
  vertical-align: middle;
}

 .something-more .text{
   font-size: 15px;
 }

.something-more .volunteer-image{
  vertical-align: middle;
}
