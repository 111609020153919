.App {
  /*text-align: center;*/
  /* padding-bottom: 10px; */
  min-height: 100%;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.card {
  background: #FFFFFF;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,0.07);
  border-radius: 5px;
}

.customize-backdrop.high-z{
  z-index: 9980;
}

h1, h2{
  text-transform: none !important;
}


/*.modal {
  background: #FFFFFF;
  box-shadow: 0 22px 49px 0 rgba(0,0,0,0.19);
  border-radius: 5px;
}*/

img {
  outline:1px solid transparent;
}

/* ::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;

} */
.error-state img{
  width: 18px;
}

.error-state .text {
  font-weight: 600;
  font-size: 13px;
  color: var(--primary-content-color);
  letter-spacing: -0.19px;
}


input.text-field, .text-field {
  border: 1px solid rgba(0,0,0,0.17);
  border-radius: 3px;
  line-height: 28px;
  min-height: 30px;
  height: fit-content;
  padding: 0;
  color: var(--primary-content-color);
  outline: none;
  padding-left: 12px;
  box-shadow: 0 0;
  background-color: transparent;
  scrollbar-width: none;
  max-width: 100%;
}

input.text-field.disabled, .text-field.disabled {
  opacity: .5;
}
input.text-field::placeholder{
  opacity: 0.6;
  font-size: 13px;
  color: var(--primary-content-color);
  background-color: transparent;
  letter-spacing: 0;
}

input.text-field:focus{
  border: 1px solid var(--input-highlight);
  outline: none;
  box-shadow: 0 0;
}

input.missing-border, .missing-border{
  border-color: var(--missing-color);
}
