.custom-event .card{
  margin-top:  56px;
}

.custom-event {
  margin-bottom: 60px;
}

.custom-event .disclaimer{
  width: 560px;
  margin-bottom: 16px;
  color: var(--primary-content-color);
  font-size: 15px;
}

.custom-event .disclaimer b{
  color: var(--strong-content-color);
  font-weight: 600;
}


.group.time input {
  border: 1px solid rgba(0,0,0,0.17);
  border-radius: 3px;
  height: 30px;
  color: var(--primary-content-color);
  outline: none;
  padding-left: 12px;
  -webkit-box-shadow: 0 0;
  box-shadow: 0 0;
  background-color: transparent;
  width: 168px;
  margin-right: 12px;
  vertical-align: top;
}

.group.time .SingleDatePicker, .group.time .SingleDatePickerInput, .group.time .DateInput {
  width: fit-content;
}

.group.time .SingleDatePicker input{
  width: 220px;
}


.custom-event .text-field.address{
  width: 450px;
  margin-bottom: 20px;
}

.custom-event .about-you{
  border-top: 1px solid rgba(0,0,0, 0.08);
  padding-top: 19px;
  border-bottom: 1px solid rgba(0,0,0, 0.08);
  margin-bottom: 26px;
  padding-bottom: 32px;

}
.custom-event .about-you .details{
  width: 540px;
}

.custom-event .about-you .group{
  margin-top: 5px;
  margin-bottom: 20px;

}

.card.my-form label{
  color: var(--primary-content-color);
  font-size: 15px;
  font-weight: normal;
  margin-left: 5px;
  margin-right: 10px;
}
