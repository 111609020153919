.about-us .main-section{
  width: 996px;
  margin-left: auto;
  margin-right: auto;
}

.about-us .main-section .name-title{
  display: inline-block;
  vertical-align: middle;
  margin-left: 12px;
}

.about-us .main-section .name-title .name{
  font-size: 19px;
  font-weight: 600;
  color: var(--strong-content-color);
}

.about-us .main-section .name-title .title{
  font-size: 15px;
  color: var(--primary-content-color);
}

.about-us .person .text{
  font-size: 15px;
  color: var(--primary-content-color);
  letter-spacing: -0.21px;
}

.about-us .person .text p{
  margin-bottom: 20px;
}


.about-us .main-section img{
  width: 88px;
  display: inline-block;
}

.about-us .main-section .person{
  width: 290px;
  display: inline-block;
  margin-left: 85px;
  margin-right: 135px;
  vertical-align: top;
}

.about-us .main-people{
  margin-top: 53px;
  padding-bottom: 35px;
  border-bottom: 1px solid rgba(0,0,0, 0.08);
  margin-bottom: 37px;
}

.about-us .whys{
  padding-bottom: 43px;
}


.about-us .why{
  display: inline-block;
  width: 310px;
  margin-right: 30px;
  vertical-align: top;
}

.about-us .why.last{
  margin-right: 0px;
}

.about-us .section-header{
  font-size: 15px;
  letter-spacing: -0.27px;
  color: var(--strong-content-color);
  font-weight: 600;
  margin-bottom: 15px;
}

.about-us .text{
  font-size: 13px;
  /* What types of events: */
  color: var(--primary-content-color);
}

.about-us .text p{
  margin-bottom: 15px;
}

.about-us .main-people .picture{
  margin-bottom: 16px;
}
