.adder-modal{
  width: 493px;
  height: 446px;
  box-shadow: 0 22px 49px 0 rgba(0,0,0,0.19);
  margin-top: 100px;
  border-radius: 5px;
}

.adder-modal .adder-header{
  min-height: 52px;
  width: 100%;
  position: relative;
  border-bottom: 1px solid rgba(132,132,132, 0.2)
}

.adder-modal .adder-footer{
  min-height: 52px;
  width: 100%;
  position: relative;
  border-top: 1px solid rgba(132,132,132, 0.2)
}

.adder-modal .adder-footer .secondary-button, .adder-modal .adder-footer .custom-button {
  margin-right: 12px;
}

.adder-modal .adder-header .text{
  opacity: 0.5;
  font-weight: 600;
  font-size: 17px;
  color: #000000;
  line-height: 52px;
  margin-left: 12px;
}

.adder-modal .adder-header .search-box{
  margin-top: 11px;
}

.adder-modal .adder-sidebar{
  padding-top: 6px;
  text-transform: capitalize;
  width: 140px;
  height: 340px;
  display: inline-block;
  border-right: 1px solid rgba(132,132,132, 0.2);
  color: var(--primary-content-color);
  padding-left: 13px;
  letter-spacing: -0.21px;
  line-height: 30px;
  font-size: 15px;
  vertical-align: top;
}

.adder-modal .modal-content{
  border-radius: 5px;
}

.adder-modal .adder-sidebar .category{
  cursor: pointer;
}

.adder-modal .adder-sidebar .category.selected{
  font-weight: 700;
  color: var(--highlight-color);

}

.item-adder{
  display: inline-block;
  vertical-align: top;
  width: 353px;
  padding-left: 12px;
  overflow: auto;
  height: 340px;
  scrollbar-width: none;
}

.item-adder::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}

.item-adder img.add{
  padding-left: 12px;
  cursor: pointer;
  display: inline-block;
}
